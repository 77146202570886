import "./style.css"
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

import Layout from "../../layout/layout";
import { logoutUser } from "../../../utility/Apis";
import { useMyContext } from "../../context";
import { getBetHistory } from "../../../utility/Apis";

import { ReactComponent as SettingsIcon } from "../../../assets/images/icons/settings.svg"
import { ReactComponent as EnterIcon } from "../../../assets/images/icons/enter.svg"
// import { ReactComponent as GamepadIcon } from "../../../assets/images/icons/gamepad.svg"
// import { ReactComponent as SlotsIcon } from "../../../assets/images/icons/slots1.svg"
// import { ReactComponent as FishIcon } from "../../../assets/images/icons/fish.svg"
// import { ReactComponent as TableGamesIcon } from "../../../assets/images/icons/table-game.svg"
import { ReactComponent as GoldCoinIcon } from "../../../assets/images/icons/gold-coin.svg"

import profileAvatar from "../../../assets/images/mock/profile-avatar.png"
import Settings from "../Settings";
import { Tooltip } from "@mui/material";

const pageLimit = 10;
const initialFilter = {
  fromDate: null,
  toDate: null,
  betType: "All",
  offset: 0,
};

const Profile = () => {
  const { userDetails } = useMyContext();
  const [activeFilter, setActiveFilter] = useState('all')
  const [showSettings, setShowSettings] = useState(false);
  const [betHistory, setBetHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingLogout, setLoadingLogout] = useState(false)
  const [betHistoryCount, setBetHistoryCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterVal, setFilterVal] = useState(initialFilter);
  
  const getAllBetHistory = async (filter) => {
    try {
      setLoading(true);
      const res = await getBetHistory({
        ...filter,
        limit: pageLimit,
      });
      if (res?.status === 200) {
        const newHistoryArr = betHistory;
        newHistoryArr.push(...(res?.history || []))
       
        setBetHistory(newHistoryArr);
        setBetHistoryCount(res?.count);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {  
    getAllBetHistory(filterVal)
    // eslint-disable-next-line
  }, [activeFilter]);

  const handlePageClick = ({ page }) => {
    setFilterVal({ ...filterVal, offset: page * pageLimit });
    setCurrentPage(page);
    getAllBetHistory(filterVal);
  };

  const handleLogout = async () => {
    const refreshToken = localStorage.getItem("refreshToken") || "";
    try {
      setLoadingLogout(true);
      const res = await logoutUser({
        refreshToken: refreshToken,
      });

      if (res?.status === 200) {
        setLoadingLogout(false);
        const items = ["token", "refreshToken", "sessionId"];
        items.forEach((key) => localStorage.removeItem(key));
        toast.success("Logout Successfully");
        window.location.href = "/";
      }
    } catch (error) {
      setLoadingLogout(false);
      console.log(error);
    }
  };

  const handleClickAll = () => {
    setBetHistory([])
    setCurrentPage(0);
    setFilterVal({ ...filterVal, ...initialFilter })
    setActiveFilter('all')
  }
  
  const handleClickMonth = () => {
    const lastMonth = new Date()
    lastMonth.setMonth(new Date().getMonth() - 1)

    const monthFilter = {
      fromDate: lastMonth.toISOString().slice(0, 10),
      toDate: new Date().toISOString().slice(0, 10),
    }
    setBetHistory([])
    setCurrentPage(0);
    setFilterVal({ ...filterVal, ...monthFilter })
    setActiveFilter('month');
  }

  const handleClickYear = () => {
    const lastYear = new Date()
    lastYear.setFullYear(new Date().getFullYear() - 1)

    const yearFilter = {
      fromDate: lastYear.toISOString().slice(0, 10),
      toDate: new Date().toISOString().slice(0, 10),
    }
    setBetHistory([])
    setCurrentPage(0);
    setFilterVal({ ...filterVal, ...yearFilter })
    setActiveFilter('year');
  }

  const renderLoading = () => (
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
      variant={loadingLogout ? "danger" : "light"}
    />
  );

  const getFormattedDate = date => new Date(date)
    .toLocaleDateString("en-US",
    {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }
  );

  const getFormattedTime = time =>  new Date(time)
    .toLocaleTimeString( "en-US",
    {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }
  );

  return (
    <Layout>
      <div className="profile__container">
        <Settings
          isOpen={showSettings}
          handleClose={() => setShowSettings(false)}
        />
        <div className="profile__container-header">
          <div className="pofile__header-wrapper">
            <img src={profileAvatar} alt="profile-avatar" />
            <div className="profile__user-details">
              <p className="profile__details-name">
                <p>{userDetails?.userName || ''}</p>
                <p>ID: {userDetails?.agentId || '' }</p>
              </p>
              <button
                disabled={loadingLogout}
                onClick={() => handleLogout()}
                className="profile__logout-button"
              >
                {loadingLogout 
                  ? (renderLoading())
                  : (<EnterIcon />)
                }
                <p>Log out</p>
              </button>
            </div>
          </div>
          <button
            onClick={() => setShowSettings(true)}
            className="profile__settings-button"
          >
            <SettingsIcon />
            <p>Settings</p>
          </button>
        </div>
        <div className="profile__bet-history">
          <div className="profile__history-header">
            <p>Bet History</p>
            <div className="profile__history-buttons">
              <button 
                onClick={() => handleClickAll()}
                className={`
                  profile__date-filter 
                  ${activeFilter === 'all' ?'--active' : ''
                }`}
              >
                <p>All</p>
              </button>
              <button
                onClick={() => handleClickMonth()}
                className={`
                  profile__date-filter 
                  ${activeFilter === 'month' ?'--active' : ''
                }`}
              >
                <p>Month</p>
              </button>
              <button
                onClick={() => handleClickYear()}
                className={`
                  profile__date-filter 
                  ${activeFilter === 'year' ?'--active' : ''
                }`}
              >
                <p>Year</p>
              </button>
            </div>
          </div>
          {/* <div className="profile__game-filter">
            <button className="profile__game-button --active">
              <GamepadIcon />
              <p>All</p>
            </button>
            <button className="profile__game-button">
              <SlotsIcon />
              <p>Slots</p>
            </button>
            <button className="profile__game-button">
              <FishIcon />
              <p>Fish</p>
            </button>
            <button className="profile__game-button">
              <TableGamesIcon />
              <p>Table games</p>
            </button>
          </div> */}
          <ul className="profile__history-list">
            <li className="profile__list-header">
              <div className="profile__list-col">
                <p>Game</p>
              </div>
              <div className="profile__list-col">
                <p>Time</p>
              </div>
              <div className="profile__list-col">
                <p>Date</p>
              </div>
              <div className="profile__list-col">
                <p>Bet</p>
              </div>
              <div className="profile__list-col --flex2">
                <p>Multiplier</p>
              </div>
              <div className="profile__list-col">
                <p>Win</p>
              </div>
              <div className="profile__list-col">
                <p>Before Balance</p>
              </div>
              <div className="profile__list-col">
                <p>After Balance</p>
              </div>
            </li>
            {!betHistory.length && loading && (
              <div
                style={{  textAlign: "center", height: "35vh" }}
                className="d-flex align-items-center justify-content-center"
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  role="status"
                  aria-hidden="true"
                  variant="light"
                />
              </div>
            )}
            {betHistory.map((bet, index) => (
              <li
                key={`${bet.transactionId}-${index}`} 
                className="profile__list-item"
              >
                <div className="profile__list-col --ellipsis">
                  <Tooltip
                    followCursor
                    enterDelay={600}
                    title={bet?.MasterCasinoGame?.name}
                  >
                    <p>{bet?.MasterCasinoGame?.name}</p>
                  </Tooltip>
                </div>
                <div className="profile__list-col">
                  <p>{getFormattedTime(bet.updatedAt)}</p>
                </div>
                <div className="profile__list-col">
                  <p>{getFormattedDate(bet.updatedAt)}</p>
                </div>
                <div className="profile__list-col --white-text">
                  <GoldCoinIcon />
                  <p>{
                     Math?.abs(
                        Number(bet?.betAmount || bet?.bet_amount || bet?.amount ||0)
                      )?.toFixed(2)
                  }</p>
                </div>
                <div className="profile__list-col --gold-text --flex2">
                  <p>2.2x</p>
                </div>
                <div className="profile__list-col --white-text">
                  <GoldCoinIcon />
                  {console.log(bet)}
                  <p>{
                    bet?.actionType === "win"
                      ? Math?.abs(
                          Number(
                            bet?.beforeBalance - bet?.afterBalance
                          )
                        )?.toFixed(2)
                      : Math?.abs(
                          Number(bet?.betAmount || bet?.bet_amount || bet?.amount || 0)
                        )?.toFixed(2)
                  }</p>
                </div>
                <div className="profile__list-col --white-text">
                  <GoldCoinIcon />
                  <p>
                    {Number(bet?.actionType !== "win"
                        ? bet?.beforeBalance + (bet?.betAmount || 0)
                        : bet?.beforeBalance || 0
                    ).toFixed(2)}
                  </p>
                </div>
                <div className="profile__list-col --white-text">
                  <GoldCoinIcon />
                  <p>{Number(bet.afterBalance || 0).toFixed(2)}</p>
                </div>
              </li>
            ))}
          </ul>
          <div className="profile__list-counter">
            <p><b>{betHistory.length}</b> / {betHistoryCount}</p>
            <button
              onClick={() => {
                handlePageClick(currentPage + 1);
              }}
              disabled={betHistory.length === betHistoryCount || loading}
            >
              {loading && (renderLoading())}
              Show more
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
