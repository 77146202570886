/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import { Spinner } from "react-bootstrap";
import CoinIcon from "../../assets/images/header/balance-icon/coin.svg"
import WalletIcon from "../../assets/images/icons/wallet.svg"
import SettingsIcon from "../../assets/images/icons/settings.svg"
// import NotificationsIcon from "../../assets/images/icons/notifications.png"
import LogoutIcon from "../../assets/images/icons/logout.svg"
import VerseLogoDesktop from "../../assets/images/logo-full.png";
import VerseLogoMobile from "../../assets/images/logo-mobile.png";
import "./style.css";
import { useMyContext } from "../context";
import SearchContent from "./searchContent";
import { Link, useNavigate } from "react-router-dom";
import ResposiveSearch from "./resposiveSearch";
import ResposiveAccount from "./resposiveAccount";
import {
  getAllFilteredGames,
  getUserDetail,
  logoutUser,
} from "../../utility/Apis";
import { toast } from "react-toastify";
import { socket } from "../../socketConnection";
import { socoketFun } from "../../App";
import HeaderPopup from "./headerPopup";
import { popupShow } from "../../utility/Apis";
import UserProgress from "./UserProgress";
import Settings from "../account/Settings";

function Header({ showNotifications, setShowNotifications }) {
  const {
    windowWidth,
    forLogin,
    handleLoginShow,
    handleSignupShow,
    handleShowTransection,
    handleHideTransection,
    userDetails,
    setUserDetails,
    showBalance,
    setGameDetail,
    setIsGamePlaying,
    gameSubCategory,
  } = useMyContext();

  const navigate = useNavigate();
  const inGame =
    window?.location?.href?.split("/")[3] === "game" ? true : false;
  const [loading, setLoading] = useState(false);
  const [, setScrolling] = useState(false);
  const [search, setSearch] = useState(false);
  const [respsearch, setRespsearch] = useState(false);
  const [resaccount, setResAccount] = useState(false);
  const [showSettings, setShowSettings] = useState(false)
  // const [refresh, setRefresh] = useState(false);

  const inputRef = useRef(null);
  useEffect(() => {
    if (!inGame && userDetails?.inGame) {
      socket.emit("update-game-status", { inGame, id: userDetails?.id });
      setIsGamePlaying(false);
    }
  }, []);
  const getUserData = async () => {
    try {
      const token = localStorage.getItem("token") || "";
      const res = await getUserDetail();
      if (res.data) {
        setUserDetails(res?.data, { token: token });
      } else {
        localStorage.clear();
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    socket.on("transection_details", (response) => {
      getUserData();
      setUserDetails({
        ...userDetails,
        cryptoWallet: {
          [response?.currency]: response?.updatedUsdAmount,
        },
      });
      // console.log("response=====>>>>>>>", response);
      handleHideTransection();
      toast.success("payment successfull", {
        toastId: "payment",
      });
    });
    socoketFun().on("balance", (response) => {
      setUserDetails({
        ...userDetails,
        cryptoWallet: {
          [response?.currency]: response?.balance,
        },
      });
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [activeLink, setActiveLink] = useState(
    localStorage.getItem("activeAccountMenuLink") || "/account/dashboard"
  );

  useEffect(() => {
    localStorage.setItem("activeAccountMenuLink", activeLink);
  }, [activeLink]);

  useEffect(() => {
    setActiveLink(window.location.pathname);
  }, []);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  useEffect(() => {
    if (search === true) {
      document.body.classList.add("hiddenScoll");
    } else {
      document.body.classList.remove("hiddenScoll");
    }
  }, [search]);

  // const handleClickSearch = () => {
  //   setSearch(!search);
  // };

  const handleCloseSearch = () => {
    setSearch(false);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setAllFilteredGames([]);
  };

  const handleResponsiveHide = () => {
    setRespsearch(false);
  };

  const handleResAccountShow = () => {
    setResAccount(!resaccount);
  };

  // const handleRefresh = () => {
  //   setRefresh(true);
  //   setTimeout(() => {
  //     setRefresh(false);
  //   }, 1000);
  // };

  const handleLogout = async () => {
    const refreshToken = localStorage.getItem("refreshToken") || "";
    try {
      setLoading(true);
      const res = await logoutUser({
        refreshToken: refreshToken,
      });

      if (res?.status === 200) {
        socket.emit('logout-user', refreshToken);
        setLoading(false);
        const items = ["token", "refreshToken", "sessionId"];
        items.forEach((key) => localStorage.removeItem(key));
        toast.success("Logout Successfully");
        window.location.href = "/";
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const useSearchOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setSearch(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperSearchRef = useRef(null);
  useSearchOutsideAlerter(wrapperSearchRef);


  // search functionality =========
  const [allFilteredGames, setAllFilteredGames] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const searchParams = async (event, selectedId = "default") => {
    const value = event?.target?.value;
    // if ((selectedId === 'default' || !value)) return setAllFilteredGames([]);
    if (value?.length > 2 || selectedId) {
      try {
        setLoading(true);
        const res = await getAllFilteredGames({
          page: 1,
          limit: 20,
          search: value?.toString() || "",
          isMobile: false,
          subCategoryId: selectedId,
        });
        if (res?.msg === "success") {
          setLoading(false);
          setAllFilteredGames(res?.data?.games);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  //const [gameShow, setGameShow] = useState(false);
  // const [gameData, setGameData] = useState({});
  const handleGameShow = (game) => {
    // setGameShow(true);
    //setGameData(game);
    setGameDetail(game);
    navigate(`/game/${game?.masterCasinoGameId}`);
  };
  // const handleGameClose = () => {
  //   setGameShow(false);
  //   navigate("/view-all/false/lobby/lobby");;
  // };

  const [isPopupOpen, setPopupOpen] = useState(true);
  const [imageData, setImageData] = useState(null);

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await popupShow(); // Assuming popupShow is your API function
        setImageData(res?.data?.data); // Set image data in state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {showSettings && (
        <Settings 
          isOpen={showSettings}
          handleClose={() => setShowSettings(false)}
        />
      )}
      <header ref={wrapperSearchRef}>
        {isPopupOpen && imageData && (
          <HeaderPopup onClose={handlePopupClose}>
            {/* {imageData && ( // Check if imageData exists before rendering */}
            <>
              <img src={imageData.image} alt={imageData.detailedText} />
              <p>{imageData.detailedText}</p>
            </>
            {/* )} */}
          </HeaderPopup>
        )}

        <div className="middle">
            <div className="header-content">
              <div className="header-logo">
                <div className="tab-logo">
                  <Link to="/" className="logo">
                    {/* <img src={logo} alt="" /> */}
                    <img className="header-logo--desktop" src={VerseLogoDesktop} alt="Versebit logo" />
                    <img className="header-logo--mobile" src={VerseLogoMobile} alt="Versebit logo" />
                  </Link>
                </div>
              </div>

              {!search && (
                <>
                  {forLogin ? (
                    <>
                      {/* <span
                        className="btn deposit"
                        text_key="MENU__DEPOSIT"
                        onClick={() => {
                          handleShowTransection("deposit");
                        }}
                      > */}
                      {/* GET https://www.coinbase.com/oauth/authorize?response_type=code&client_id=YOUR_CLIENT_ID&redirect_uri=YOUR_REDIRECT_URL&state=SECURE_RANDOM&scope=wallet:accounts:read */}
                      {/* <Link to="https://www.coinbase.com/oauth/authorize?response_type=code&client_id=c73f4685c27886d91941bd3f3e4522373b900ebcc327233194cc774141057a37&redirect_uri=http://50.16.64.132/&state=SECURE_RANDOM&scope=wallet:accounts:read"> */}
                      {/* Wallet */}
                      {/* </Link> */}
                      {/* </span> */}
                      <div className="header-content__wallet">
                        {showBalance && (
                          <div className="header-content__wallet-balance">
                            <img
                              className="header-content__wallet-icon"
                              src={CoinIcon}
                              alt="Coin Icon"
                            />
                            <span>
                              {userDetails &&
                              userDetails?.cryptoWallet?.USD
                                ? Number(
                                    userDetails?.cryptoWallet?.USD
                                  )?.toFixed(2)
                                : "0.00"}
                            </span>
                          </div>
                        )}
                        <button
                          onClick={() => handleShowTransection("deposit")}
                          className="header-content__wallet-button"
                        >
                          <img
                            className="header-content__wallet-icon"
                            src={WalletIcon}
                            alt="Wallet Icon"
                          />
                          Wallet
                        </button>
                      </div>
                      <div className="header-content__user-panel">
                        <Link
                          to="/account/profile"
                          className="header-content__user-progress-button"
                          onClick={() => {
                            handleLinkClick(
                              "/account/profile"
                            );
                          }}
                        >
                          <UserProgress />
                        </Link>
                        <div className="header-content__user-details">
                          <p className="header-content__user-text">
                            {userDetails?.userName || '' }
                          </p>
                          <p className="header-content__user-text --subtitle">
                            ID: {userDetails?.agentId || '' }
                          </p>
                        </div>
                        <div className="header-content__user-buttons-wrapper">
                          <button
                            className="header-content__user-button"
                            onClick={() => setShowSettings(true)}
                          >
                            <img src={SettingsIcon} alt="settings"/>
                          </button>
                          {/* <button
                            className="header-content__user-button"
                            onClick={() => setShowNotifications(!showNotifications)}
                          >
                            <img src={NotificationsIcon} alt="notifications"/>
                          </button> */}
                          <button
                            className="header-content__user-button"
                            onClick={handleLogout}
                          >
                            {loading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  variant="light"
                                />
                              ) : (
                                <img src={LogoutIcon} alt="logout"/>
                              )}
                          </button> 
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div/>
                      <div className="header-content__buttons-wrapper">
                        <button
                          className="header-content__login"
                          onClick={handleLoginShow}
                        >
                          Log In
                        </button>
                        <button
                          className="header-content__signup"
                          onClick={handleSignupShow}
                        >
                          Register
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
        </div>
      {search && (
        <SearchContent
          allFilteredGames={allFilteredGames}
          loading={loading}
          // wrapperRef={wrapperSearchRef}
          handleCloseSearch={handleCloseSearch}
          handleGame={handleGameShow}
          gameSubCategory={gameSubCategory}
          setSelectCategory={setSelectCategory}
          optimizedversion={searchParams}
          selectCategory={selectCategory}
        />
      )}
      </header>
      {windowWidth <= 991 && (
        <>
          {
            <ResposiveSearch
              handleResponsiveHide={handleResponsiveHide}
              respsearch={respsearch}
              gameSubCategory={gameSubCategory}
            />
          }
          {forLogin && (
            <ResposiveAccount
              resaccount={resaccount}
              handleResAccountShow={handleResAccountShow}
              handleLogout={handleLogout}
            />
          )}
        </>
      )}
    </>
  );
}

export default Header;
