import './style.css';
import React, { useState } from 'react';
import { ReactComponent as PersonsIcon } from '../../assets/images/icons/persons.svg';
import { ReactComponent as GridIcon } from '../../assets/images/icons/grid.svg';
import { ReactComponent as FolderIcon } from '../../assets/images/icons/folder.svg';



import Layout from '../layout/layout';
import Overview from './Overview';
import Campaigns from './Campaigns';

const ReferralAward = () => {
  const [currentTab, setCurrentTab] = useState('overview')
  return (
    <Layout>
      <div className="referral-award">
        <div className="referral-award__header">
          <div className="referral-award__header-item">
            <PersonsIcon />
            <p className="referral-award__header-title">
              Referral award
            </p>
          </div>
          <div className="referral-award__header-item --butons">
            <button
              onClick={() => setCurrentTab('overview')}
              className={
                `referral-award__header-button ${currentTab === 'overview' ? '--active' : ''}`
                }
              >
              <GridIcon />
              <p>Overview</p>
            </button>
            <button
              onClick={() => setCurrentTab('campaigns')}
              className={
                `referral-award__header-button ${currentTab === 'campaigns' ? '--active' : ''}`
                }
              >
              <FolderIcon />
              <p>Capmaigns</p>
            </button>
          </div>
        </div>
        {
          currentTab === 'overview' ? (
            <Overview />
          ):(
            <Campaigns />
          )
        }
      </div>
    </Layout>
  );
};

export default ReferralAward;
