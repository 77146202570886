import React from "react";
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import { Select, MenuItem, ListItemText, Checkbox  } from "@mui/material";
import { ReactComponent as SlotsIcon } from '../../assets/images/icons/slots1.svg'
import { ReactComponent as StarIcon } from '../../assets/images/icons/star.svg'
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search.svg"
// import { ReactComponent as CheckboxIcon } from "../../assets/images/icons/checkbox.svg"
// import { ReactComponent as CheckboxCheckedIcon } from "../../assets/images/icons/checkbox-checked.svg"
import { useMyContext } from "../context";

function GameSearch({
  allGamesData: gamesData,
  size,
  page,
  setLoading,
  providers,
  getProviderDetails,
  setSearchValue,
  searchvalue,
  // allGamesByCategory,
  handlechange,
}) {
  // const [activeProviders, setActiveProviders] =  useState([]);
  // const [activeSort, setActiveSort] = useState('') 
  const { activeCategory, gameSubCategory } = useMyContext([]);
  // const providersRef = useRef();
  // const sortRef = useRef()

  // const handleSelectProvider = ({ target: { value } }) => {
  //   setActiveProviders(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };

  const getCategoryName = () => 
    gameSubCategory.find(i => String(i.masterGameSubCategoryId) === String(activeCategory))?.name;
  console.log(activeCategory)
  return (
    <div className="filter">
      <div className="filter__header">
        {activeCategory === "favorite" ? (
            <StarIcon />
          ): (
            <SlotsIcon />
        )}
        <p>{ activeCategory === "favorite" ? activeCategory : getCategoryName()}</p>
        <div className="filter__header-badge">{size}</div>
      </div>
      <div className="filter__wrapper">
        <div className="filter__search-wrapper">
          <SearchIcon />
          <input
            type="text"
            placeholder_key="CASINO__SEARCH_GAMES"
            className="search-game"
            placeholder="Search for slots..."
            // value={searchvalue}
            onChange={handlechange}
          />
        </div>
        {/* <div className="filter__select-wrapper">
          <Select
            ref={providersRef}
            multiple
            displayEmpty
            className="filter__select"
            id="filter-active-provider"
            value={activeProviders}
            onChange={handleSelectProvider}
            renderValue={() => 'Provider'}
            IconComponent={KeyboardArrowDownIcon}
            MenuProps={{ className: "filter__select-menu", anchorEl: providersRef.current }}
          >
            <MenuItem disabled value="">Provider</MenuItem>
            {providers.map((provider) => (
              <MenuItem key={provider.name} value={provider.name}>
                <ListItemText primary={provider.name} />
                <Checkbox 
                  checked={activeProviders.includes(provider.name)}
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                />
              </MenuItem>
            ))}
          </Select>
          <Select
            ref={sortRef}
            displayEmpty
            id="filter-active-sort"
            className="filter__select"
            value={activeSort}
            IconComponent={KeyboardArrowDownIcon}
            MenuProps={{ className: "filter__select-menu", anchorEl: sortRef.current }}
            onChange={({ target: value }) => setActiveSort(value.value)}
          >
            <MenuItem value="">Popular</MenuItem>
            {providers.map((provider) => (
              <MenuItem key={provider.name} value={provider.name}>
                {provider.name}
              </MenuItem>
            ))}
          </Select>
        </div> */}
      </div>
    </div>
  );
}

export default GameSearch;
