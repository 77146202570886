import React from 'react';
import { 
  // Balance,
  ExpandMore,
} from '@mui/icons-material';

import { ReactComponent as CopyIcon } from "../../../assets/images/icons/copy.svg"

const WithdrawForm = ({
  coinData,
  isWithdraw,
  withDrawData,
  withdrawname,
  withdrawimage,
  getName,
  callApiDeposit,
  setWithdrawData,
  toggleWithdrawDropdown,
}) => {
  const mockedBalance = 10456.12

  const handleAddAmount = (amount) => {
    if ((withDrawData.amount + amount) > mockedBalance) {
      return
    }
    setWithdrawData({
      ...withDrawData,
      amount: parseInt(withDrawData.amount || 0) + parseInt(amount),
    })
  }

  const handleAddMax = () => {
    setWithdrawData({
      ...withDrawData,
      amount: mockedBalance,
    })
  }

  return (
    <div
      className="transection-dialog__withdraw" 
      data-page="withdraw"
    >
      <div className="transection-dialog__withdraw-wrapper">
        <div className="transection-dialog__form-item">
          <label text_key="PAYMENTS__ACCOUNT_CURRENCY">
            Choose a currency
          </label>
          <div
            className="select"
            name="currencySelect"
            onClick={toggleWithdrawDropdown}
          >
            <div
              className='transection-dialog__deposit-select'
              text_key="PAYMENTS__SELECT_ACCOUNT_CURRENCY"
            >
              {/* Select Account Currency */}
              <p>
                <img
                  src={withdrawimage}
                  style={{ height: "18px", marginRight: "4px" }}
                  alt=""
                />
                {withdrawname}
              </p>
              <ExpandMore />
            </div>
            {isWithdraw && (
              <ul className="select-options">
                {coinData?.map((item) => (
                  <li
                    pci={item.pci}
                    rel={item.rel}
                    onClick={() =>
                      getName(item, { value: "withdraw" })
                    }
                  >
                    <div className="icon-wrapper">
                      <img src={item.icon} alt="icon" />
                    </div>{" "}
                    {item.coin}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="transection-dialog__form-item" name="balanceField">
          <label text_key="PAYMENTS__BALANCE">Withdrawal amount</label>
          <div className="transection-dialog__input-wrapper">
            <input
              className="transection-dialog__withdraw-amount"
              type="number"
              value={withDrawData.amount}
              onChange={(e) =>
                setWithdrawData({
                  ...withDrawData,
                  amount: e.target.value,
                })
              }
            />
            <p className="transection-dialog__current-balance">Balance:  {mockedBalance.toLocaleString('en')}</p>
          </div>
          <div className="transection-dialog__amount-buttons">
            <button
              type="button"
              onClick={() => handleAddAmount(100)}
            >
              100
            </button>
            <button
              type="button"
              onClick={() => handleAddAmount(500)}
            >
              500
            </button>
            <button
              type="button"
              onClick={() => handleAddAmount(1000)}
            >
              1000
            </button>
            <button
              type="button"
              onClick={() => handleAddAmount(5000)}
            >
              5000
            </button>
            <button
              type='button'
              onClick={handleAddMax}
            >
              Max
            </button>
          </div>
        </div>
        <div className="transection-dialog__form-item">
          <label text_key="PAYMENTS__DEPOSIT_ADDRESS">
            {/* Deposit Address */}
            Withdrawal Address
          </label>
          <div className="transection-dialog__input-wrapper">
            <input
              type="text"
              name="depositAddress"
              className="transection-dialog__withdraw-amount"
              value={withDrawData.address}
              placeholder="Wallet Address"
              onChange={(e) =>
                setWithdrawData({
                  ...withDrawData,
                  address: e.target.value,
                })
              }
            />
            <CopyIcon
              onClick={() => navigator.clipboard.writeText(withDrawData.address)}
              className="transection-dialog__copy-icon"
              />
          </div>
          <div className='transection-dialog__text-warning'>
            Make sure that the selected network corresponds to the network of the platform from which you withdraw or to which you send funds.
          </div>
          <div className="transection-dialog__withdraw-values">
            <p>Transaction fee:</p>
            <p>$1</p>
          </div>
          <div className="transection-dialog__withdraw-values">
            <p>Receive amount:</p>
            <p>{withDrawData.amount}</p>
          </div>
        </div>
        <button
          className="transection-dialog__confirm-button"
          text_key="PAYMENTS__CONFIRM"
          onClick={() =>
            callApiDeposit(withDrawData, { value: "withdraw" })
          }
        >
          Withdraw
        </button>
      </div>
    </div>
  )
};

export default WithdrawForm;