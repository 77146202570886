import { toast } from "react-toastify";

import { useMyContext } from "../components/context";
import { makeUserFavoriteGame } from "../utility/Apis";

export const useChangeUserFavorites = () => {
  const { setFavorites } = useMyContext()
  const changeUserFavorites = async (gameId) => {
    try {
      const res = await makeUserFavoriteGame(gameId);
      const {
        data: { message },
      } = res || {};

      if (res?.status === 200) {
        setFavorites((prevFavorites) => {
          // Check if the game is already in favorites
          if (prevFavorites.includes(gameId)) {
            // Remove the game immutably if it exists in favorites
            return prevFavorites.filter((favId) => favId !== gameId);
          } else {
            // Add the game immutably if it doesn't exist in favorites
            return [...prevFavorites, gameId];
          }
        });

        return toast.success(message, {
          toastId: "send-code",
          autoClose: 2000,
        });
      }
    } catch (err) {
      return toast.error("Something went wrong!", {
        toastId: "send-code",
        autoClose: 2000,
      });
    }
  };

  return { changeUserFavorites };
};
