import './style.css';
import React from 'react';
import { Modal } from '../../commomUi/Modal';
import { Close } from '@mui/icons-material';

import { ReactComponent as CircleAddIcon } from '../../../assets/images/icons/circle-add.svg';
import { ReactComponent as LockIcon } from '../../../assets/images/icons/lock.svg';

const CreateCampaign = ({ isOpen, handleClose }) => {
  return (
    <Modal open={isOpen}>
      <div className="create-campaign">
        <div className="create-campaign__header">
          <div className="create-campaign__title">
            <CircleAddIcon />
            <p>Create campaign</p>
          </div>
          <button
            onClick={handleClose}
            className="create-campaign__close-button"
          >
            <Close />
          </button>
        </div>
        <div className="create-campaign__content">
          <div className="create-campaign__item">
            <label>Name</label>
            <input
              className="create-campaign__input"
              placeholder="Enter a name of capmaign"
            />
          </div>
          <div className="create-campaign__item">
            <label>Code</label>
            <input
              className="create-campaign__input"
              placeholder="Enter a code of capmaign"
            />
          </div>
          <div className="create-campaign__item">
            <label>Link </label>
            <input
              className="create-campaign__input"
              placeholder="https://cryptoroll123"
            />
            <LockIcon />
          </div>
          <button className="create-campaign__button">
            Create
          </button>
        </div>
      </div>
    </Modal>
  )
};

export default CreateCampaign;