import "./style.css"
import React from "react";
import { useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as MenuIcon } from "../../assets/images/icons/menu1.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close.svg";
// import { ReactComponent as MegaphoneIcon } from "../../assets/images/icons/megaphone.svg";
import { ReactComponent as GamepadIcon } from "../../assets/images/icons/gamepad.svg";
import { ReactComponent as ChatIcon } from "../../assets/images/icons/chat1.svg";
import { ReactComponent as ProfileIcon } from "../../assets/images/icons/profile.svg";
import { useMyContext } from '../context'


const Tapbar = ({ showSidebar, setShowSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    setActiveCategory,
    setShowLobbyGames,
    setShowChat,
  } = useMyContext();

  const handleClickAllGames = () => {
    if (location.pathname !== "/") navigate("/");
    setActiveCategory('lobby');
    setShowLobbyGames(true);
    setShowSidebar(false)
  };

  const handleClickProfile = () => {
    navigate("/account/profile");
    setShowSidebar(false)
  };
  
  return (
    <div className="tapbar">
      <button
        onClick={() => setShowSidebar(!showSidebar)}
        className={`tapbar__button ${showSidebar ? '--open' : ''}`}
      >
        {showSidebar? (
          <span className="tapbar__icon-wrapper">
            <CloseIcon />
          </span>
        ):(
          <span className="tapbar__icon-wrapper">
            <MenuIcon style={{ width: '19.5px', height: '13px'}}/>
          </span>
        )}
        <p>Menu</p>
      </button>
      {/* <button
        className="tapbar__button"
      >
        <MegaphoneIcon style={{ width: '21.67px', height: '19.79px' }}/>
        <p>Promotions</p>
      </button> */}
      <button
        onClick={handleClickAllGames}
        className="tapbar__button"
      >
        <span className="tapbar__icon-wrapper">
          <GamepadIcon style={{ width: '23.83px', height: '15.17px' }}/>
        </span>
        <p>All games</p>
      </button>
      <button
        className="tapbar__button"
        onClick={() => setShowChat(true)}
      >
        <span className="tapbar__icon-wrapper">
          <ChatIcon style={{ width: '21.67px', height: '19.5px' }}/>
        </span>
        <p>Chat</p>
      </button>
      <button
        onClick={handleClickProfile}
        className="tapbar__button"
      >
        <span className="tapbar__icon-wrapper">
          <ProfileIcon style={{ width: '18.1px', height: '20.58px' }}/>
        </span>
        <p>Profile</p>
      </button>
    </div>
  )
}

export default Tapbar;