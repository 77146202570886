import "./style.css"
import React from "react";
import Layout from "../layout/layout";

import { ReactComponent as TrophyIcon } from "../../assets/images/icons/trophy.svg"
import { ReactComponent as GoldCoinIcon } from "../../assets/images/icons/gold-coin.svg"
import { ReactComponent as Top1Icon } from "../../assets/images/leaderboard/top1-crown.svg"
import { ReactComponent as Top2Icon } from "../../assets/images/leaderboard/top2-crown.svg"
import { ReactComponent as Top3Icon } from "../../assets/images/leaderboard/top3-crown.svg"

import profile1 from "../../assets/images/mock/profile-avatar2.jpeg";
import profile2 from "../../assets/images/mock/Slot3.png";
import profile3 from "../../assets/images/mock/profile-avatar.png";

const Leaderboard = ({ data = dataMock }) => {
  const top1 = data[0];
  const top2 = data[1];
  const top3 = data[2];

  const LeaderboardBox = ({ user }) => (
    <div className="leaderboard__leader-box">
      <div className="leaderboard__box-text">
        <p>Win</p>
        <p>
          <GoldCoinIcon />
          {user.win}
        </p>
      </div>
      <div className="leaderboard__box-divider"/>
      <div className="leaderboard__box-text --score">
        <p>Score</p>
        <p>{user.score}</p>
      </div>
    </div>
  );

  return (
    <Layout>
      <div className="leaderboard">
        <div className="leaderboard__header">
          <TrophyIcon />
          <p className="leaderboard__title">
            Leaderboard
          </p>
        </div>
        <div className="leaderboard__container">
          <div className="leaderboard__top-places">
            <div className="leaderboard__leader-wrapper">
              <div className="leaderboard__leader-avatar">
                <Top2Icon />
                <div className="leaderboard__avatar-wrapper">
                  <img src={top2.imgUrl} alt="profile avatar"/>
                </div>
                <p>{top2.username}</p>
              </div>
              <LeaderboardBox user={top2}/>
            </div>
            <div className="leaderboard__leader-wrapper">
              <div className="leaderboard__leader-avatar">
                <Top1Icon />
                <div className="leaderboard__avatar-wrapper">
                  <img src={top1.imgUrl} alt="profile avatar"/>
                </div>
                <p>{top1.username}</p>
              </div>
              <LeaderboardBox user={top1}/>
            </div>
            <div className="leaderboard__leader-wrapper">
              <div className="leaderboard__leader-avatar">
                <Top3Icon />
                <div className="leaderboard__avatar-wrapper">
                  <img src={top3.imgUrl} alt="profile avatar"/>
                </div>
                <p>{top3.username}</p>
              </div>
              <LeaderboardBox user={top3}/>
            </div>
          </div>
        </div>
        <div className="leaderboard__leader-list">
          <div className="leaderboard__list-header">
            <button className="leaderboard__list-button --active">
              Month
            </button>
            <button className="leaderboard__list-button">
              Week
            </button>
            <button className="leaderboard__list-button">
              Day
            </button>
          </div>
          <div className="leaderboard__list-wrapper">
            <ul className="leaderboard__list">
              {data.slice(3, 7).map((userData, index) => (
                <li className="leaderboard__list-item">
                  <div className="leaderboard__item-col">
                    <p>#{index+4}</p>
                  </div>
                  <div className="leaderboard__item-col">
                    <img src={userData.imgUrl} alt="user img" />
                    <p>{userData.username}</p>
                  </div>
                  <div className="leaderboard__item-col">
                    {userData.score}
                  </div>
                  <div className="leaderboard__item-col --white-text">
                    <GoldCoinIcon />
                    <p>{userData.win}</p>
                  </div>
                </li>
              ))}
            </ul>
            <ul className="leaderboard__list">
              {data.slice(7, 11).map((userData, index) => (
                <li className="leaderboard__list-item">
                  <div className="leaderboard__item-col">
                    <p>#{index+8}</p>
                  </div>
                  <div className="leaderboard__item-col">
                    <img src={userData.imgUrl} alt="user img" />
                    <p>{userData.username}</p>
                  </div>
                  <div className="leaderboard__item-col">
                    {userData.score}
                  </div>
                  <div className="leaderboard__item-col --white-text">
                    <GoldCoinIcon />
                    <p>{userData.win}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Leaderboard;

const dataMock = [
  {
    imgUrl: profile1,
    username: "Foxpen",
    score: 222,
    win: "1,156.00",
  },
  {
    imgUrl: profile2,
    username: "Foxpen",
    score: 148,
    win: "1,090.00",
  },
  {
    imgUrl: profile3,
    username: "Messai",
    score: 166,
    win: "900.00",
  },
  {
    imgUrl: profile3,
    username: "user234",
    score: 111,
    win: "877.00",
  },
  {
    imgUrl: profile3,
    username: "user22345",
    score: 110,
    win: "764.00",
  },
  {
    imgUrl: profile3,
    username: "user5422",
    score: 109,
    win: "877.00",
  },
  {
    imgUrl: profile3,
    username: "user9432",
    score: 104,
    win: "764.00",
  },
  {
    imgUrl: profile3,
    username: "kkmuller",
    score: 100,
    win: "877.00",
  },
  {
    imgUrl: profile3,
    username: "user1345",
    score: 99,
    win: "764.00",
  },
  {
    imgUrl: profile3,
    username: "user0888",
    score: 94,
    win: "877.00",
  },
  {
    imgUrl: profile3,
    username: "user285465",
    score: 80,
    win: "764.00",
  },
]