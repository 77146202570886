import "./style.css"
import React from "react";
import { Modal } from "../commomUi/Modal";
import { Close } from "@mui/icons-material";
import luckyWheel from "../../assets/images/mock/luckyWheel.png"
const LuckyWheel = ({ isOpen, handleClose }) => {
  return (
    <Modal open={isOpen}>
      <div className="lucky-wheel">
        <div className="lucky-wheel__header">
          <button onClick={handleClose}>
            <Close />
          </button>
        </div>
        <div className="lucky-wheel__content">
          <img src={luckyWheel} alt="lucky wheel" />
        </div>
        <div className="lucky-wheel__footer">
          <p>Spin & Win</p>
        </div> 
      </div>
    </Modal>
  );
};

export default LuckyWheel;