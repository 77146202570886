import './style.css'
import React from 'react';
import authbanner from "../../../assets/images/header/auth/new-auth-banner.png";
import closeIcon from "../../../assets/images/header/auth/icon/close.svg";
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';

const Auth = ({
  showLogin,
  showSignup,
  handleLoginClose,
  handleSignupClose,
  handleLoginShow,
  handleSignupShow,
  handleForLogin
}) => {
  const handleClose = () => {
    handleLoginClose();
    handleSignupClose();
  };

  return (
    <div className='auth-container'>
      <div className="auth-container__banner">
        <img src={authbanner} alt="auth-banner" />
      </div>
      <div className="auth-container__wrapper">
        <div className="auth-container__header">
          <div className="auth-container__header-button-wrapper">
            <button
              className={`auth-container__header-button ${showLogin ? '--active': ''}`}
              text_key="LOGIN__LOG_IN"
              onClick={handleLoginShow}
            >
              Log In
            </button>
            <button
              className={`auth-container__header-button ${showSignup ? '--active': ''}`}
              text_key="LOGIN__SIGN_UP"
              onClick={handleSignupShow}
            >
              Sign Up
            </button>
          </div>
          <button className="auth-container__header-button-close" onClick={handleClose}>
            <img src={closeIcon} alt="closeIcon" />
          </button>
        </div>
        {showLogin ? (
            <LoginForm 
              handleLoginClose={handleLoginClose}
              handleForLogin={handleForLogin}
            />
          ) : (
            <SignupForm
              handleSignupClose={handleSignupClose}
              handleLoginShow={handleLoginShow}
              />  
          )
        }
      </div>
    </div>
  );
};

export default Auth;