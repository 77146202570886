import React, { useState } from "react";
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { ReactComponent as LockIcon } from  "../../../assets/images/icons/lock.svg"
// import { ReactComponent as CircleInfoIcon } from  "../../../assets/images/icons/circle-info.svg"
// import { ReactComponent as ChevronRightIcon } from  "../../../assets/images/icons/chevron-rirght.svg"

const DefaultForm = ({
  userDetails
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className="account-modal__input-wrapper --name">
        <input
          type="text"
          name="name"
          value={`${userDetails.firstName} ${userDetails.lastName}`}
          readonly="readonly"
        />
        <LockIcon />
      </div>
      {/* <div className="account-modal__email-content">
        <div className="account-modal__input-wrapper">
          <p>Email</p>
          <input
            type="text"
            name="email"
            value="vvvbb***@gmail.com"
            readonly="readonly"
          />
          <button
            // onClick={() => handleSetCurrentForm('confirm-email')}
            className="account-modal__input-icons"
          >
            <CircleInfoIcon />
            <ChevronRightIcon />
          </button>
        </div>
        <div className="account-modal__confirm-text">
          <p>Email is not confirmed</p>
        </div>
      </div> */}
      <div className="account-modal__password-content">
        <div className="account-modal__input-wrapper">
          <p>Password</p>
          <input
            type={!showPassword ? "password" : "text"}
            name="password"
            value={!showPassword ? "*********" : userDetails.password}
            readonly="readonly"
          />
          <IconButton 
            className="account-modal__show-password"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            {showPassword ? (<VisibilityOff />) : (<Visibility />)}
          </IconButton>
        </div>
        {/* <button
          onClick={() => handleSetCurrentForm('password-recovery')}
          className="account-modal__change-password"
        >
          <p>Change password</p>
        </button> */}
      </div>
      {/* <div className="account-modal__switch-wrapper">
        <div className="account-modal__switch-text">
          <p>Balance</p>
          <p>Disable display of balance in header</p>
        </div>
        <Switch
          checked={showBalance}
          onClick={handleClickSwitch}
          className="account-modal__balance-switch"
        />
      </div> */}
    </>
  );
};

export default DefaultForm