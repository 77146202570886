import './style.css'
import React from 'react';

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import avatar from '../../../assets/images/header/user-profile-icon/avatar.svg'

const UserProgress = ({ userLevel = 24, currentProgress = 999, nextLevel = 1000 }) => (
  <div className='user-profile__container'>
    <div className='user-profile__avatar-wrapper'>
      <CircularProgressbarWithChildren
        value={55}
        strokeWidth={7}
        circleRatio={0.65}
        styles={buildStyles({
            rotation: 1.675,
            strokeLinecap: "butt",
            pathColor: "#FF1E00",
            trailColor: "#0C0C0D"
          })
        }
      >
        <img src={avatar} alt='avatar' className='user-profile__avatar'/>
      </CircularProgressbarWithChildren>
    </div>
    <div className='user-profile__progress'>
      <p>{currentProgress}</p>/
      <p>{nextLevel}</p>
    </div>
    <div className='user-profile__user-level'>
      <p>{userLevel}</p>
    </div>
  </div>
);

export default UserProgress;