/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import Layout from '../layout/layout'
import GameSearch from './gameSearch'
import './style.css'
import GameCard from './gameCard'
import { getAllProviders, getAllgamesByCategory } from '../../utility/Apis'
import { useMyContext } from '../context'
import { toast } from 'react-toastify'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Lobby from '../lobby/lobby'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/grid'
// import RecentWins from './RecentWins'
// import LiveBets from './Livebets'

function Casino() {
  const {
    loading: loadingShowMore,
    setLoading: setShowMoreLoading,
    userDetails,
    handleLoginShow,
    windowWidth,
    // gameSubCategory,
    setFavorites,
    activeCategory,
    // setActiveCategory,
    showLobbyGames,
    setShowLobbyGames,
  } = useMyContext()
  const [gameShow, setGameShow] = useState(false)
  const [size, setSize] = useState(null)
  const [allGamesData, setAllGamesData] = useState([])
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('page')) || 2,
  )
  //const [game, setGame] = useState(null);
  const [shownGames, setShownGames] = useState(1)
  const [providers, setProviders] = useState([])
  const [providerData, setProviderData] = useState({})
  const [gameLoader, setGameLoader] = useState(false)
  
  
  const isAllgame = activeCategory === 'all-games' || false

  const handleGameShow = () => {
    setGameShow(true)
  }
  // const handleGameClose = () => {
  //   setGameShow(false);
  //   setIsGamePlaying(false);
  //   navigate(pathname);
  // };
  const headerGamePopup = gameShow && 'gamePopup'

  const getPageNumber = (pageNum) => {
    if (pageNum > 1) {
      setPage(pageNum)
      getGamesByCatergory()
    }
  }

  const [searchvalue, setSearchValue] = useState('')

  const debounce = (func, delay) => {
    let timer
    return (...args) => {
      clearTimeout(timer)
      timer = setTimeout(() => func(...args), delay)
    }
  }

  // Debounced version of handleSearch
  const debouncedHandleSearch = debounce((value) => {
    setSearchValue(value)
  }, 500)

  const handleSearch = (e) => {
    const { value } = e.target
    // if (value === undefined) {
    //   debouncedHandleSearch("");
    // } else {
    debouncedHandleSearch(value)
    // }
  }

  // games list fetch here============
  useEffect(() => {
    // window.scrollTo(0, 0);
    const getGames = async () => {
      let payload = {
        limit: 30,
        pageNumber: 1,
        popular: 0,
        isMobile: windowWidth < 576 ? true : false,
        userId: userDetails?.id || false,
      }
      setPage(2)
      if (isAllgame) {
        payload.allGame = true
        if (providerData?.masterCasinoProviderId) {
          payload.providerId = providerData?.masterCasinoProviderId
          if (searchvalue) payload.search = searchvalue
        } else if (searchvalue) {
          payload.search = searchvalue
        }
      } else if (activeCategory) {
        if (providerData?.masterCasinoProviderId) {
          payload.providerId = providerData?.masterCasinoProviderId
          if (searchvalue) payload.search = searchvalue
        } else if (searchvalue) {
          payload.search = searchvalue
        } else {
          payload.subCategoryId = activeCategory
        }
      }
      try {
        setGameLoader(true)
        if (activeCategory === 'lobby') {
          if (searchvalue) {
            setShowLobbyGames(false)
          } else {
            setShowLobbyGames(true)
          }
        }
        let res = await getAllgamesByCategory(payload)
        let newGames = res?.data?.games || []
        if (res?.msg === 'success') {
          setSize(res?.data?.queriedCount)
          setShownGames(newGames?.length)
          setAllGamesData(newGames)
          if (newGames?.length > 0) {
            const favoriteGames = newGames
              .filter(
                (game) => game?.UserFavoriteCasinoGame?.userMasterCasinoGameId,
              )
              .map(
                (game) => game?.UserFavoriteCasinoGame?.userMasterCasinoGameId,
              )
            setFavorites(favoriteGames)
          }
          setGameLoader(false)
        } else {
          setGameLoader(false)
        }
      } catch (error) {
        setGameLoader(false)
      }
    }
    // if (userDetails?.id) {
    if (sessionStorage.getItem('pageSize') !== null) {
      getGamesByCatergory()
    } else {
      getGames()
    }

    // }
    // eslint-disable-next-line
  }, [
    userDetails,
    providerData?.masterCasinoProviderId,
    searchvalue,
    activeCategory,
  ])

  const getGamesByCatergory = async () => {
    try {
      const pageSizeFromStorage = sessionStorage.getItem('pageSize')
      let payload = {
        limit: pageSizeFromStorage || 30,
        isMobile: windowWidth < 576 ? true : false,
        userId: userDetails?.id ? userDetails?.id : false,
      }
      if (pageSizeFromStorage === null) {
        payload.pageNumber = page
        payload.popular = 0
      } else {
        setGameLoader(true)
      }
      if (isAllgame) {
        payload.allGame = true
      }
      if (providerData?.masterCasinoProviderId) {
        if (isAllgame) {
          payload.providerId = providerData?.masterCasinoProviderId
          payload.allGame = true
        } else if (activeCategory) {
          payload.providerId = providerData?.masterCasinoProviderId
          // payload.subCategoryId = id;
        }
        setPage(page + 1)
      }
      if (activeCategory && !providerData?.masterCasinoProviderId) {
        payload.subCategoryId = activeCategory
      }
      setShowMoreLoading(true)
      let res = await getAllgamesByCategory(payload)
      let newGames = res?.data?.games || []
      // if (!searchvalue) return setAllGamesData(newGames);
      if (res?.msg === 'success') {
        sessionStorage.removeItem('pageSize')
        setGameLoader(false)
        setSize(res?.data?.queriedCount)
        setShownGames((prevLength) => prevLength + newGames?.length)
        setAllGamesData((prevData) => [...prevData, ...newGames])
        if (newGames?.length > 0) {
          const favoriteGames = newGames
            .filter(
              (game) => game?.UserFavoriteCasinoGame?.userMasterCasinoGameId,
            )
            .map((game) => game?.UserFavoriteCasinoGame?.userMasterCasinoGameId)

          setFavorites(favoriteGames)
        }
        setShowMoreLoading(false)
      } else {
        toast.error(res?.msg, {
          toastId: 'game-error',
        })
      }
    } catch (error) {
      setShowMoreLoading(false)
      // setGameLoader(false);
    }
  }

  const getAllProvidersData = async () => {
    try {
      const res = await getAllProviders()
      if (res?.msg === 'success') {
        setProviders(res?.data?.providers)
      }
    } catch (err) {
    setProviders([])
    }
  }

  // useEffect(() => {
  //   getGamesByCatergory();
  //   // eslint-disable-next-line
  // }, [page, searchvalue]);

  useEffect(() => {
    getAllProvidersData()
    // eslint-disable-next-line
  }, [])
  // get gameId from onclick===
  const getGameDetails = () => {
    if (!localStorage.getItem('token')) {
      handleLoginShow()
    }
  }

  // get proviser details from onclick===
  const getProviderDetails = (provider) => {
    if (provider) setProviderData(provider)
  }

  useEffect(() => {
    if (!gameLoader && !showLobbyGames) {
      const savedScrollPosition = sessionStorage.getItem('scrollPosition')
      if (savedScrollPosition) {
        window.scrollTo(0, parseInt(savedScrollPosition))
        // sessionStorage.removeItem("scrollPosition");
      }
    }
    // eslint-disable-next-line
  }, [allGamesData?.length > 0, gameLoader])

  return (
    <>
      <Layout headerGamePopup={headerGamePopup}>
        <div className="container">
          <main className="casino">
            {showLobbyGames ? (
              <div className="cassino__loby-header">
                {/* <div className="cassino__banner-wrapper">
                  <div className="cassino__banner">
                    <div className="cassino__banner-content">
                      <p>Sweet promotions are waiting for you</p>
                      <button>MORE INFO</button>
                    </div>
                  </div>
                  <div className="cassino__banner">
                    <div className="cassino__banner-content">
                      <p>Sweet promotions are waiting for you</p>
                      <button>MORE INFO</button>
                    </div>
                  </div>
                </div> */}
                {/* <RecentWins /> */}
              </div>
            ):(
              <GameSearch
                page={page}
                size={size}
                providers={providers}
                allGamesData={allGamesData}
                searchvalue={searchvalue}
                setSearchValue={setSearchValue}
                setShowMoreLoading={setShowMoreLoading}
                allGamesByCategory={getGamesByCatergory}
                getProviderDetails={getProviderDetails}
                handlechange={handleSearch}
              />
            )}
            <div className="content">
              {showLobbyGames ? (
                <Lobby setShowLobbyGames={setShowLobbyGames} />
              ) : (
                <GameCard
                  searchvalue={searchvalue}
                  handleGameShow={handleGameShow}
                  allGamesData={allGamesData}
                  loading={loadingShowMore}
                  getPageNumber={getPageNumber}
                  page={page}
                  queriedCount={size}
                  getGame={getGameDetails}
                  shownGames={shownGames}
                  size={size}
                  gameLoader={gameLoader}
                />
              )}
              {/* <LiveBets /> */}
            </div>
          </main>
        </div>
      </Layout>
    </>
  )
}

export default Casino
