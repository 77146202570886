import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { startselectedGame } from "../../utility/Apis";
import Layout from "../layout/layout";
import { useMyContext } from "../context";
// import MobileIframeGames from "./MobileIFrame";
import IosFrame from "./IosFrame";
// import MobileIframeGames from "./MobileIFrame";
import { isIOS } from "react-device-detect";
import MobileIframeGames from "./MobileIFrame";
import GameFrameWrapper from "./GameFrameWrapper";

function IframeGames() {
  const { windowWidth, windowHeight, setFavorites } = useMyContext();
  // const navigate = useNavigate();
  const params = useParams();
  const isMobile = windowWidth <= 991 || windowHeight <= 768 
  // const handleGameClose = () => {
  //   navigate(-1);
  // };
  const { gameId } = params;
  

  // const [selectedCurrency, setSelectedCurrency] = useState(null);
  // const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // const handleGamePay = () => {
  //   setDropdownOpen(!isDropdownOpen);
  // };

  // const handleCurrencySelect = (currency) => {
  //   console.log("currency===", currency);
  //   setSelectedCurrency(currency);
  //   setDropdownOpen(false);
  // };

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      // setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (window.location.pathname === "/casino/casino") {
      setIsActive(true);
    }
  }, []);

  const [gameLink, setGameLink] = useState("");
  const handleStartGame = async () => {
    try {
      const res = await startselectedGame({
        // selectedCurrency: selectedCurrency,
        gameId: gameId,
        isMobile: windowWidth < 500 ? true : false,
      });
      console.log(res)
      if (res?.success) {
        setGameLink(res?.data);
      }
      if (res?.gameDetails?.UserFavoriteCasinoGame) {
        setFavorites((prevFavorites) => {
          // Check if the game is already in favorites
          if (prevFavorites.includes(gameId)) {
            return prevFavorites;
          } else {
            return [...prevFavorites, +gameId];
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (gameId && !gameLink) {
      handleStartGame();
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line
  }, [gameId]);
  
  return isMobile ? (
      <GameFrameWrapper
        isMobile
        gameId={Number(gameId)}
      >
        {isIOS ? <IosFrame /> : <MobileIframeGames />}
      </GameFrameWrapper>
      ) : (
        <Layout>
          <GameFrameWrapper gameId={Number(gameId)}>
            <div className={isActive ? "game-popup miniGames" : "game-popup"}>
              <div className="game-info-wrapper">
                <div className="game-wrapper">
                  <div className="game">
                    <div
                      className="iframe-helper"
                      // className={isFullscreen ? 'fullscreen-element' : ''}
                    >
                      {/* {gameLink ? ( */}
                      <iframe
                        src={gameLink}
                        frameborder="0"
                        title="Game-play"
                      ></iframe>
                      {/* ) : (
                  <div className="choose-currency"> */}
                      {/* <div
                      className="select"
                      name="userCurrencySelect"
                      onClick={handleGamePay}
                    >
                      <div className="select-styled active">
                        {selectedCurrency
                          ? selectedCurrency
                          : "Select Game Currency"}
                      </div>
                      {isDropdownOpen && (
                        <ul className="select-options" ref={dropdownRef}>
                          <li
                            onClick={() => handleCurrencySelect("BTC")}
                            rel="302"
                          >
                            <div className="icon-wrapper">
                              <img src={btc} alt="btc" />
                            </div>
                            BTC
                          </li>
                          <li
                            onClick={() => handleCurrencySelect("ETH")}
                            rel="180"
                          >
                            <div className="icon-wrapper">
                              <img src={eth} alt="eth" />
                            </div>
                            ETH
                          </li>
                          <li
                            onClick={() => handleCurrencySelect("LTC")}
                            rel="202"
                          >
                            <div className="icon-wrapper">
                              <img src={ltc} alt="ltc" />
                            </div>
                            LTC
                          </li>
                        </ul>
                      )}
                    </div> */}
                      {/* <div
                      className="btn play-game"
                      text_key="CASINO__PLAY_GAME"
                      onClick={() => handleStartGame()}
                    >
                      Play Game
                    </div>
                  </div>
                )} */}
                    </div>
                  </div>
                  {/* <div className="game-review">
                    <div className="game-review-container">
                      <div className="game-info">
                        <div className="title">{game?.name}</div>
                        <p>{(game && game["MasterCasinoProvider.name"]) || ""}</p>
                      </div>
                      <div className="fav-container"></div>
                    </div>
                    <div className="action-buttons">
                      <div className="full-screen" onClick={toggleFullscreen}>
                        <FullScreen />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </GameFrameWrapper>
        </Layout>
      );
}

export default IframeGames;
