import "./style.css"
import React, { useEffect, useRef, useState } from "react";
import EmojiPicker from 'emoji-picker-react';
import { Close } from "@mui/icons-material";
import { Box, ClickAwayListener, IconButton } from "@mui/material";
import { useMyContext } from "../context";

import { ReactComponent as ChatIcon } from "../../assets/images/icons/chat1.svg"
import { ReactComponent as EmojiIcon } from "../../assets/images/icons/emoji.svg"
import { ReactComponent as PaperPlaneIcon } from "../../assets/images/icons/paper-plane.svg"
import { ReactComponent as ReplyIcon } from "../../assets/images/icons/arrow-share-left.svg"

import profile from "../../assets/images/mock/profile-avatar.png";
import { socket } from "../../socketConnection";

const Chat = () => {
  const messagesRef = useRef();
  const inputRef = useRef();
  const [message, setMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [replyingTo, setReplyingTo] = useState(null)
  const [connectedUsers, setConnectedUsers] = useState(0)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { showChat, setShowChat, handleLoginShow } = useMyContext()

  const handleAddMessage = (newChatMessage) => {
    setChatMessages([newChatMessage, ...chatMessages]);
    messagesRef.current.scrollTo(0, messagesRef.current.scrollHeight)
  }

  useEffect(() => {
    socket.on('chat-message', handleAddMessage);

    return () => {
      socket.off('chat-message');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessages])

  useEffect(() => {
    socket.on('connected-users', data => setConnectedUsers(data));

    return () => {
      socket.off('connected-users');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedUsers])

  useEffect(() => {
    socket.emit('refresh-connected-users')
  }, [])

  const handleSendMessage = (evt) => {
    evt.preventDefault()
  
    if (!localStorage.getItem("token")) {
      handleLoginShow();
    } else {
      const newMessage = {
        message,
        replyTo: replyingTo?.id || null,
      }
      
      if(!socket.auth.token) {
        socket.disconnect();
        socket.auth.token = localStorage.getItem("token");
        socket.connect();
      }
      
      socket.emit('chat-message', newMessage)
      setMessage('')
      setReplyingTo(null)
    }
  };

  const handleReply = (message) => {
    setReplyingTo(message)
    inputRef.current.focus()
  }

  const closeEmojiPick = () => {
    inputRef.current.focus()
    setShowEmojiPicker(false);
  }

  const handleEmojiClick = ({ emoji }) => {
    setMessage(message + emoji );
    closeEmojiPick()
  }


  const getFormattedTime = time =>  new Date(time)
    .toLocaleTimeString( "en-US",
    {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }
  );

  const renderRepliedMessage = messageId => {
    const repliedMessage = chatMessages.find(message => message.id === messageId);
    return (
      <div className="chat__message-replied">
        {repliedMessage ? (
          <>
            <p>{repliedMessage.username}</p>
            <p>{repliedMessage.message}</p>
          </>
        ) : (
          <p>Message not available</p>
        )}
      </div>
    );
  }

  return (
    <div className={`chat ${showChat ? '--show' : ''}`}>
      <div className="chat__header">
        <div className="chat__header-wrapper">
          <div className="chat__header-title">
            <ChatIcon />
            <p>Online chat</p>
          </div>
          <div className="chat__header-badge">
            <div className="chat__badge-indicator"/>
            <p>{connectedUsers}</p>
          </div>
        </div>
        <IconButton
          className="chat__header-close"
          onClick={() => setShowChat(false)}
        >
          <Close/>
        </IconButton>
      </div>
      <div className="chat__messages" ref={messagesRef}>
        {chatMessages.map((message) => (
          <div className="chat__message-item" key={message.id}>
            <div className="chat__message-header">
              <div className="chat__message-title">
                <img
                  src={profile}
                  alt="user profile"
                  style={{ width: '24px', height: '24px'}}
                  />
                  <p>{message.username}</p>
                  {message.userType  && (
                    <p className={`chat__message-badge --${message.userType}`}>
                      {message.userType}
                    </p>
                  )}
              </div>
              <p className="chat__message-time">
                {getFormattedTime(message.timestamp)}
              </p>
            </div>
            <div className="chat__message-content">
              <div className="chat__message-text">
                {message?.replyTo && renderRepliedMessage(message.replyTo)}
                <p>{message.message}</p>
              </div>
              <ReplyIcon
                role="button"
                onClick={() => handleReply(message)}
                style={{ minWidth: '14.5px', minHeight: '12px' }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="chat__footer">
        {replyingTo && (
          <div className="chat__footer-reply">
            <div className="chat__message-replied">
              <p>{replyingTo.username}</p>
              <p>{replyingTo.message}</p>
            </div>
            <IconButton
              className="chat__close-reply"
              onClick={() => setReplyingTo(null)}
            >
              <Close fontSize="small"/>
            </IconButton>
          </div>
        )}
        <div className="chat__footer-wrapper">
          <form
            onSubmit={handleSendMessage}
            className="chat__input-wrapper"
          >
            <input 
              ref={inputRef}
              value={message}
              maxLength={255}
              placeholder="Message..."
              onChange={({ target: { value }}) => setMessage(value)}
            />
            <button className="chat__button-emoji" type="button">
              <EmojiIcon
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              />
            </button>
            {showEmojiPicker && (
              <ClickAwayListener onClickAway={closeEmojiPick}>
                <Box>
                  <EmojiPicker
                    theme="dark"
                    autoFocusSearch={false}
                    onEmojiClick={handleEmojiClick}
                  />
                </Box>
              </ClickAwayListener>
            )}
          </form>
          <button
            type="button"
            disabled={!message}
            onClick={handleSendMessage}
            className="chat__button-sent"
          >
            <PaperPlaneIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chat;