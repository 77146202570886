import "./style.css"
import React, { useState } from "react"
import { Close } from "@mui/icons-material";

import { Modal } from "../commomUi/Modal";
import { ReactComponent as TicketIcon } from "../../assets/images/icons/ticket.svg"
import usdt from "../../assets/images/icons/usdt.svg"
import bannerImage from "../../assets/images/sidebar/promotion-background.png"

const COIN_ICON_SRC  = {
 usdt 
}

const Promocode = ({ isOpen, handleClose}) => {
  const [promocode, setPromocode] = useState("NewYear25");
  return (
    <Modal
      open={isOpen}
      handleClose={handleClose}
    >
      <div className="promocode">
        <div className="promocode__header">
          <div className="promocode__header-title">
            <TicketIcon />
            <p>Promo code</p>
          </div>
          <button
            onClick={handleClose} 
            className="promocode__close-button"
          >
            <Close />
          </button>
        </div>
        <div className="promocode__content">
          <div className="promocode__input-content">
            <div className="promocode__input-wrapper">
              <label>Promocode</label>
              <input
                value={promocode}
                onChange={({ target: { value } }) =>  setPromocode(value)}
              />
            </div>
            <button>Activate</button>
          </div>
          <div className="promocode__banner">
            <p className="promocode__banner-title">
              PROMO2025
            </p>
            <p lassName="promocode__banner-description">
              Promocode for new users
            </p>
            <img
              src={bannerImage}
              alt="Promo banner"
              className="promocode__banner-image"
            />
          </div>
          <div className="promocode__divider"/>
          <div className="promocode__history">
            <p className="promocode__history-header">
              Activation history
            </p>
            <ul className="promocode__list">
              {dataMock.map(item => (
                <li className="promocode__list-item">
                    <p>{item.date}</p>
                    <div className="promocode__item-wrapper">
                      <img src={COIN_ICON_SRC[item.coin]} alt="Coin"/>
                      <p>{item.value}</p>
                    </div>
                    <p>{item.code}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default Promocode;

const dataMock = [
  {
    date: "07.01.25 12:44",
    value: "10,456.12",
    coin: "usdt",
    code: "HELLO25"
  },{
    date: "07.01.25 12:44",
    value: "10,456.12",
    coin: "usdt",
    code: "HELLO25"
  },{
    date: "07.01.25 12:44",
    value: "10,456.12",
    coin: "usdt",
    code: "HELLO25"
  },{
    date: "07.01.25 12:44",
    value: "10,456.12",
    coin: "usdt",
    code: "HELLO25"
  },{
    date: "07.01.25 12:44",
    value: "10,456.12",
    coin: "usdt",
    code: "HELLO25"
  },{
    date: "07.01.25 12:44",
    value: "10,456.12",
    coin: "usdt",
    code: "HELLO25"
  },{
    date: "07.01.25 12:44",
    value: "10,456.12",
    coin: "usdt",
    code: "HELLO25"
  },{
    date: "07.01.25 12:44",
    value: "10,456.12",
    coin: "usdt",
    code: "HELLO25"
  },
]