import React from 'react';
import { Spinner } from "react-bootstrap";
import { QRCodeSVG } from "qrcode.react";
import { ExpandMore } from '@mui/icons-material';
import { ReactComponent as CopyIcon } from "../../../assets/images/icons/copy.svg"

const DepositForm = ({
  image,
  name,
  isOpen,
  loading,
  getName,
  coinData,
  addressValue,
  callApiDeposit,
  toggleDropdown,
}) => {
  return (
    <div
      className="transection-dialog__deposit" 
      data-page="deposit"
    >
    <div className="transection-dialog__deposit-wrapper">
      <button className="transection-dialog__deposit-promotion"/>
      <div className="transection-dialog__form-item">
        <label text_key="PAYMENTS__ACCOUNT_CURRENCY">
          Choose a currency
        </label>
        <div
          className="select"
          name="currencySelect"
          onClick={toggleDropdown}
        >
          <div className='transection-dialog__deposit-select'>
            {/* Select Account Currency */}
            <p>
              <img
                src={image}
                style={{ height: "18px", marginRight: "4px" }}
                alt=""
              />
              {name}
            </p>
            <ExpandMore />
          </div>
          {isOpen && (
            <ul className="select-options">
              {coinData?.map((item) => (
                <li
                  key={item.coin}
                  pci={item.pci}
                  rel={item.rel}
                  onClick={() => {
                    getName(item, { value: "deposit" });
                    callApiDeposit(item, { value: "deposit" });
                  }}
                >
                  <div className="icon-wrapper">
                    <img src={item.icon} alt="icon" />
                  </div>{" "}
                  {item.coin}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {addressValue && (
        <div className="transection-dialog__adress-item">
          {!loading ? (
            <>
              {addressValue && (
                <div className="transection-dialog__qrcode-container">
                  <QRCodeSVG value={addressValue || ""}  size={120} />
                </div>
              )}
            </>
            ) : (
              <div style={{ zIndex: 99, textAlign: "center" }}>
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  role="status"
                  aria-hidden="true"
                  variant="light"
                />
              </div>
            )}
          <div className="transection-dialog__input-wrapper">
            <p text_key="PAYMENTS__DEPOSIT_ADDRESS">
              Deposit Address
            </p>
            <input
              type="text"
              name="depositAddress"
              value={addressValue}
              placeholder_key="PAYMENTS__WALLET_ADDRESS"
              readonly="readonly"
              placeholder="Wallet Address"
            />
            <CopyIcon
              onClick={() => navigator.clipboard.writeText(addressValue)}
              className="transection-dialog__copy-icon"
              />
            <div className='transection-dialog__text-warning'>
              {`Send only ${name} to this address`}
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
  )
}

export default DepositForm;