import React, { useState } from "react";

const ConfirmEmail = ({ userDetails, handleSetCurrentForm }) => {
  const [confirmationCode, setConfirmationCode] = useState('')
  return (
    <>
      <div className="account-modal__input-wrapper">
        <p>Code</p>
        <input
          type="text"
          name="confirmarion-code"
          value={confirmationCode}
          placeholder="Enter code from your Email"
          onChange={({ target: { value }}) => setConfirmationCode(value)}
        />
      </div>
      <p className="account-modal__help-text">
        Your email has not been confirmed. A confirmation code has been sent to the email address <b>sok***dio@gmail.com</b> you specified
      </p>
      <button
        onClick={() => handleSetCurrentForm('default')}
        className="account-modal__confirm-button"
      >
        <p>Confirm</p>
      </button>
  </>
  )
};

export default ConfirmEmail;