import './style.css'
import React, { useState } from 'react';

import { ReactComponent as GoldCoinIcon } from '../../../assets/images/icons/gold-coin.svg';
import { ReactComponent as PeopleAddIcon } from '../../../assets/images/icons/people-add.svg';
import { ReactComponent as MoneyBagIcon } from '../../../assets/images/icons/money-bag.svg';
import { ReactComponent as SlotsIcon } from '../../../assets/images/icons/slots1.svg';
import { ReactComponent as CloudDownloadIcon } from '../../../assets/images/icons/cloud-download.svg';
import { ReactComponent as CircleAddIcon } from '../../../assets/images/icons/circle-add.svg';
import { ReactComponent as CopyIcon } from "../../../assets/images/icons/copy.svg"

import avatar from "../../../assets/images/icons/avatar.svg"
import CreateCompaign from '../CreateCampaign';


const Overview = () => {
  const [showCreateCampaign, setShowCreateCampaign] = useState(false)
  
  return (
    <div className="referral-award__overview">
      {showCreateCampaign && (
        <CreateCompaign
          isOpen={showCreateCampaign}
          handleClose={() => setShowCreateCampaign(false)}
        />
      )}
      <div className="referral-award__cards-wrapper">
        <div className="referral-award__card-balance">
          <p className="referral-award__balance-title">
            Total balance
          </p>
          <div className="referral-award__balance-badge">
            <GoldCoinIcon />
            <p>104,670.48</p>
          </div>
          <button>
            <p>Claim</p>
          </button>
        </div>
        <div className="referral-award__cards-wrapper --column">
          <div className="referral-award__cards-wrapper">
            <div className="referral-award__card">
              <p className="referral-award__card-title">
                Total referrals
              </p>
              <div className="referral-award__card-value">
                <PeopleAddIcon />
                <p>43</p>
              </div>
            </div>
            <div className="referral-award__card">
              <p className="referral-award__card-title">
                Total winnings
              </p>
              <div className="referral-award__card-value">
                <MoneyBagIcon />
                <p>10,111.00</p>
              </div>
            </div>
            <div className="referral-award__card">
              <p className="referral-award__card-title">
                Total bets amount
              </p>
              <div className="referral-award__card-value">
                <SlotsIcon />
                <p>1 250</p>
              </div>
            </div>
          </div>
          <div className="referral-award__cards-wrapper">
            <div className="referral-award__card --campaign">
              <div className="referral-award__campaign-item">
                <div className="referral-award__campaign-title">
                  <p>Campaign</p>
                  <p>Total players referred</p>
                </div>
                <div className="referral-award__campaign-buttons">
                  <button>
                    <p>Download promo</p>
                    <CloudDownloadIcon />
                  </button>
                  <button onClick={() => setShowCreateCampaign(true)}>
                    <p>Create new campaign</p>
                    <CircleAddIcon />
                  </button>
                </div>
              </div>
              <div className='referral-award__campaign-divider'/>
              <div className="referral-award__campaign-item">
                <div className="referral-award__input-wrapper">
                  <p>Campaign Link</p>
                  <input
                    name="campaign-link"
                    value="https://cryptoroll123"
                    readonly="readonly"
                  />
                  <CopyIcon
                    onClick={() => navigator.clipboard.writeText("https://cryptoroll123")}
                    className="referral-award___copy-icon"
                    />
                </div>
                <div className="referral-award__input-wrapper">
                  <p>Campaign Code</p>
                  <input
                    name="campaign-code"
                    value="cryptoroll123"
                    readonly="readonly"
                  />
                  <CopyIcon
                    onClick={() => navigator.clipboard.writeText("cryptoroll123")}
                    className="referral-award___copy-icon"
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="referral-award__your-referrals">
        <div className="referral-award__referrals-header">
          <p>Your referrals</p>
          <div className="referral-award__referrals-tabs">
            <button className="referral-award__referrals-tab --active">
              All
            </button>
            <button className="referral-award__referrals-tab">
              Month
            </button>
            <button className="referral-award__referrals-tab">
              Year
            </button>
          </div>
        </div>
        <div className='referral-award__referrals-wrapper'>
          <ul className='referral-award__referrals-list'>
            <li className='referral-award__list-item --header'>
              <div><p>Player</p></div>
              <div><p>Date</p></div>
              <div><p>Campaign</p></div>
              <div><p>Total bets</p></div>
              <div><p>Percentage</p></div>
              <div><p>Reward</p></div>
            </li>
            {dataMock.map((referral, index) => (
              <li className='referral-award__list-item' key={`${referral.campaign}-${index}`}>
                <div className='referral-award__item-col'>
                  <img                        
                    src={referral.userImg}
                    alt="user avatar"
                  />
                  <p>{referral.user}</p>
                </div>
                <div className='referral-award__item-col'>
                  <p>{referral.date}</p>
                </div>
                <div className='referral-award__item-col'>
                  <p>{referral.campaign}</p>
                </div>
                <div className='referral-award__item-col --white-text'>
                  <GoldCoinIcon />
                  <p>{referral.totalBets}</p>
                </div>
                <div className='referral-award__item-col'>
                  <p>{referral.percentage}%</p>
                </div>
                <div className='referral-award__item-col --white-text'>
                <GoldCoinIcon />
                <p>{referral.reward}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Overview;

const dataMock = [
  {
    user: "user234",
    userImg: avatar,
    date: "07.01.25 12:44",
    campaign: "Default",
    totalBets: "1,156.00",
    percentage: 10,
    reward: "1,156.00",
  },
  {
    user: "user234",
    userImg: avatar,
    date: "07.01.25 12:44",
    campaign: "Default",
    totalBets: "1,156.00",
    percentage: 10,
    reward: "1,156.00",
  },
  {
    user: "user234",
    userImg: avatar,
    date: "07.01.25 12:44",
    campaign: "Default",
    totalBets: "1,156.00",
    percentage: 10,
    reward: "1,156.00",
  },
  {
    user: "user234",
    userImg: avatar,
    date: "07.01.25 12:44",
    campaign: "Default",
    totalBets: "1,156.00",
    percentage: 10,
    reward: "1,156.00",
  },
  {
    user: "user234",
    userImg: avatar,
    date: "07.01.25 12:44",
    campaign: "Default",
    totalBets: "1,156.00",
    percentage: 10,
    reward: "1,156.00",
  },
  {
    user: "user234",
    userImg: avatar,
    date: "07.01.25 12:44",
    campaign: "Default",
    totalBets: "1,156.00",
    percentage: 10,
    reward: "1,156.00",
  },
  {
    user: "user234",
    userImg: avatar,
    date: "07.01.25 12:44",
    campaign: "Default",
    totalBets: "1,156.00",
    percentage: 10,
    reward: "1,156.00",
  },
  {
    user: "user234",
    userImg: avatar,
    date: "07.01.25 12:44",
    campaign: "Default",
    totalBets: "1,156.00",
    percentage: 10,
    reward: "1,156.00",
  },
  {
    user: "user234",
    userImg: avatar,
    date: "07.01.25 12:44",
    campaign: "Default",
    totalBets: "1,156.00",
    percentage: 10,
    reward: "1,156.00",
  },
  {
    user: "user234",
    userImg: avatar,
    date: "07.01.25 12:44",
    campaign: "Default",
    totalBets: "1,156.00",
    percentage: 10,
    reward: "1,156.00",
  },
  {
    user: "user234",
    userImg: avatar,
    date: "07.01.25 12:44",
    campaign: "Default",
    totalBets: "1,156.00",
    percentage: 10,
    reward: "1,156.00",
  },
]