import React, { useState, useEffect } from "react";
// import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Grid, FreeMode } from "swiper/modules";
// import { FormControlLabel, Switch } from "@mui/material";

// import play from "../../assets/images/icons/play.svg";
import { useMyContext } from "../context";
import { Close, CropFree, Favorite, FavoriteBorder } from "@mui/icons-material";
import { useChangeUserFavorites } from "../../hooks/useChangeUserFavorites";

// import { ReactComponent as SpeakerIcon } from "../../assets/images/icons/speaker.svg"
// import { ReactComponent as InfoIcon } from "../../assets/images/icons/circle-info.svg"

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/grid";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";

const GameFrameWrapper = ({ isMobile = false, children, gameId }) => {
  // const swiperRef = useRef();
  const navigate = useNavigate()
  const [orientation, setOrientation] = useState(window.orientation || 0);
  const { isFullscreen, setIsFullscreen, favorites } = useMyContext();
  const { changeUserFavorites } = useChangeUserFavorites();

  useEffect(() => {
    const handleOrientationChange = () => {
      if (window.orientation === 90 || window.orientation === -90) {
        setOrientation(90); // Landscape
      } else {
        setOrientation(0); // Portrait
      }
    };

    window.addEventListener("orientationchange", handleOrientationChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);
  
  const handleClose = () => {
    setIsFullscreen(false);
    navigate(-1);
  }

  const toggleFullscreen = () => {
    const element = document.querySelector(".game-info-wrapper");

    if (!element) return;
    console.log(element)
    if (isFullscreen) {
      exitFullscreen();
    } else {
      enterFullscreen(element);
    }
  };

  const enterFullscreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
    
    setIsFullscreen(true);
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }

    setIsFullscreen(false);
  };

  const handleClickFavorite = async () => changeUserFavorites(gameId);

  return (
    <div className={`game-container__frame-wrapper ${isFullscreen ? '--fullscreen' : ''}`}>
      {isMobile && (children)}
      <div className="game-container__actions" >
        {/* <FormControlLabel
          className="game-container__demo-mode"
          control={<Switch defaultChecked />} 
        /> */}
        <div className={
          `game-container__buttons-wrapper ${orientation === 90 ? '--landscape' : ''}`
        }>
          <button
            onClick={handleClickFavorite}
            className="game-container__action-button"
          >
            {favorites?.length > 0 && favorites?.includes(gameId)
              ? (<Favorite />)
              : (<FavoriteBorder />)
            }
          </button>
          <button 
            onClick={toggleFullscreen}
            className="game-container__action-button"
          >
           <CropFree fontSize="small" />
          </button>
          <button 
            onClick={handleClose}
            className="game-container__action-button"
          >
            <Close />
          </button>
          {/* <button className="game-container__action-button">
            <SpeakerIcon />
          </button>
          <button className="game-container__action-button">
            <InfoIcon />
          </button> */}
        </div>
      </div>
      {!isMobile && (children)}
      {/* <div className="game-container__info">
        <div className="game-container__info-header">
          <p>{data.gameName}</p>
          <div className="game-container__header-badge">
            <p>{data.provider}</p>
          </div>
        </div>
        <p className="game-container__description">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
        </p>
      </div>
      <div className="mini-games-wrapper">
        <div className="head-wrapper">
          <div className="lobby__header">
            <p>{`More from ${data.provider}`}</p>
            <div className="lobby__navigation">
              <button onClick={() => swiperRef?.current?.swiper?.slidePrev()}>
                <KeyboardArrowLeft />
              </button>
              <button onClick={() => swiperRef?.current?.swiper?.slideNext()}>
                <KeyboardArrowRight />
              </button>
            </div>
          </div>
        </div>
        <div className="game-swiper-container">
          <Swiper
            ref={swiperRef}
            // direction="horizontal"
            {...settings}
            // handleAfterChange
            modules={[Grid, FreeMode]}
          >
            {data.games?.map((game) => (
              <SwiperSlide key={game?.masterCasinoGameId}>
                <div className="game-swiper-slider">
                  <div className="game-swiper-slider-img">
                    <div
                      className="game-thumb-container"
                      key={game?.masterCasinoGameId}
                    >
                      <img src={game?.thumbnailUrl} alt={game?.name} />
                    </div>
                    <div
                      className="slot-hover"
                    >
                      <span>
                        <img
                          className="play"
                          src={play}
                          alt="play icon"
                          // TODO onClick={() => handleClickPlay(game)}
                        />
                      </span>
                      <div className="star-wrapper">
                        {favorites?.length > 0 &&
                          favorites?.includes(game?.masterCasinoGameId)
                            ? (
                              <Favorite
                                // TODO onClick={() => }
                              />
                            ): (
                              <FavoriteBorder 
                                // TODO onClick={() => }
                              />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="game__name-wrapper">
                  <p>
                    {game?.name?.length > 30
                      ? game?.name?.substring(0, 30) + "..."
                      : game?.name}
                  </p>
                  <p />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div> */}
    </div>
  )
};

export default GameFrameWrapper;