import React, { useState, useEffect } from 'react';
import { Spinner } from "react-bootstrap";

import { getAllTransaction } from '../../../utility/Apis';
import { useMyContext } from '../../context';

const STATUS_LABEL = {
  complete: "Confirmed",
  "in_proccess": "In process",
  rejected: "Regected",
};

const History = () => {
  const [activeTab, setActiveTab] = useState('credit');
  const { userDetails } = useMyContext();
  const [transaction, setTransaction] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skip] = useState(0);

  const getAllTransactions = async (tab) => {
    try {
      setLoading(true);
      const payload = {
        userId: userDetails?.id,
        offset: skip,
        limit: 10,
        type: activeTab,
      };
      const res = await getAllTransaction(payload);
      setTransaction(res?.history);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllTransactions();
    // eslint-disable-next-line
  }, [activeTab]);

  const formattDateTime = (date) => (
    date ?
      `${new Date(date)
        .toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
      })} ${new Date(date)
        .toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }
      )}` 
    : ''
  )

  const renderContent = () => {
    if (loading) {
      return (
        <div
          style={{ zIndex: 99, textAlign: "center", height: "30vh" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Spinner
            as="span"
            animation="border"
            size="md"
            role="status"
            aria-hidden="true"
            variant="light"
          />
        </div>
      )
    }

    if (!transaction.length) {
      return (
        <div className="no-data">
          <div className="text">
            Transactions Not Found
          </div>
        </div>
      );
    }
  
    return (
      <ul className="transection-dialog__history-list">
        {transaction.map((item, index) => (
          <li
            key={`${item.id}-${index}`}
            className="transection-dialog__list-item">
            <div className="transection-dialog__list-col">
              <p>#{item.id}</p>
              <p className="--gray-text">{formattDateTime(item.updatedAt)}</p>
            </div>
            <div className="transection-dialog__list-col">
              <div className="transection-dialog__col-subitem">
                {/* <img src={COIN_ICON_SRC[item.coin]} alt="Coin"/> */}
                <p>{item?.usdAmount || "0.00"}</p>
              </div>
              <div className={`transection-dialog__history-badge ${item.status}`}>
                <p>{STATUS_LABEL[item.status]}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }


  return (
    <div className="transection-dialog__history">
      <div className="transection-dialog__history-header">
        <button
          onClick={() => setActiveTab('credit')}
          className={`transection-dialog__history-tab 
            ${activeTab === 'credit' ? '--active' : ''}
          `}>
          Deposit
        </button>
        <button
          onClick={() => setActiveTab('debit')}
          className={`transection-dialog__history-tab 
            ${activeTab === 'debit' ? '--active' : ''}
          `}>
          Withdraw
        </button>
      </div>
      <div className="transection-dialog__history-wrapper">
        {renderContent()}
      </div>
    </div>
  );
};

export default History;
