import React from "react";
import { Modal } from "../commomUi/Modal";
import { Close } from "@mui/icons-material";
import { ReactComponent as MegaphoneIcon } from "../../assets/images/icons/megaphone.svg"
import { ReactComponent as GoldCoindIcon } from "../../assets/images/icons/gold-coin.svg"
import { ReactComponent as CircleCheckIcon } from "../../assets/images/icons/circle-check.svg"
import { ReactComponent as CalendarIcon } from "../../assets/images/icons/calendar-clock.svg"

import slot1 from "../../assets/images/mock/Slot1.png"
import slot2 from "../../assets/images/mock/Slot2.png"
import slot3 from "../../assets/images/mock/Slot3.png"
import slot4 from "../../assets/images/mock/Slot4.png"
import slot5 from "../../assets/images/mock/Slot5.png"
import slot6 from "../../assets/images/mock/Slot6.png"

const PromoDetails = ({ promo, isOpen, handleClose }) => {
  return (
    <Modal open={isOpen}>
      <div className="promo-details">
        <div className="promo-details__header">
          <div className="promo-details__header-title">
            <MegaphoneIcon />
            <p>Promotion</p>
          </div>
           <button
              onClick={handleClose} 
              className="promo-details__close-button"
            >
              <Close />
            </button>
        </div>
        <div className="promo-details__content-wrapper">
          <div
            style={{ backgroundImage: `url(${promo.bannerUrl})` }}
            className="promo-details__promo-item"
            >
            <p className="daily-promotion__progress-title">
              {promo.title}
            </p>
            {promo.progressTotal ? (
              <div className="daily-promotion__progress-content">
                <p>Progress</p>
                <div className="daily-promotion__content-wrapper">
                  <GoldCoindIcon />
                  <div className="daily-promotion__progressbar-wrapper">
                    <p>{promo.currentProgress}/{promo.progressTotal}</p>
                    <div className="daily-promotion__progressbar">
                      <div style={{ width: `${(promo.progressTotal/100) * promo.currentProgress}%`}}/>
                    </div>
                  </div>
                </div>
              </div>
            ):(
              <div className="daily-promotion__status-badge">
                {promo.completed ? (
                  <>
                    <CircleCheckIcon />
                    <p>Completed</p>
                  </>
                ): (
                  <>
                    <CalendarIcon />
                    <p>{promo.expiresAt}</p>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="promo-details__content">
            <p className="promo-details__content-title">
              Conditions
            </p>
            <p className="promo-details__content-description">
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
            </p>
          </div>
          <div className="promo-details__content-divider"/>
          <p className="promo-details__content-title">
            Participating games
          </p>
          <div className="promo-details__slider-wrapper">
            <div className="promo-details__games-slider">
              {gamesMock.map((game, index) => (
                <div className="promo-details__game" key={`${game.name}-${index}`}>
                  <img src={game.gameImgUrl} alt={game.gameName} />
                  <div className="promo-details__game-description">
                    <p>{game.name}</p>
                    <p>{game.vendor}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PromoDetails;

const gamesMock = [
  {
    gameImgUrl: slot1,
    name: "Timetravel Tigers",
    vendor: "Yggdrasil"
  },
  {
    gameImgUrl: slot2,
    name: "Secret city Gold",
    vendor: "Pragmatic Play"
  },
  {
    gameImgUrl: slot3,
    name: "North Guardians",
    vendor: "Pragmatic Play"
  },
  {
    gameImgUrl: slot4,
    name: "Tiki Tumble",
    vendor: "Push gaming"
  },
  {
    gameImgUrl: slot5,
    name: "Jammin' Jars 2",
    vendor: "Push gaming"
  },
  {
    gameImgUrl: slot6,
    name: "Lucky Cloverland",
    vendor: "Endorphina"
  },
]