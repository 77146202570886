import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";

import { loginUser } from "../../../utility/Apis";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useMyContext } from "../../context";
import { IconButton } from "@mui/material";
import { socket } from "../../../socketConnection";
// import SocialLogin from "./SocialLogin";

function LoginForm({
  handleLoginClose,
  handleForLogin,
}) {
  const { loading, setLoading, setGameShow } = useMyContext();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const refreshSocketToken = (token) => {
    socket.disconnect();
    socket.auth.token = token;
    socket.connect();
  }

  // react query api call =======================
  const mutation = useMutation({
    mutationFn: async (userData) =>
      await loginUser({
        ...userData,
        sessionId: localStorage.getItem("sessionId"),
      }),
    onMutate: () => {
      // Set loading to true when the mutation starts
      setLoading(true);
    },
    onSuccess: (data) => {
      const { tokens, status } = data || {};
      if (status === 200) {
        const { token } = tokens?.access || {};
        const { refresh } = tokens || {};
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refresh?.token);
        refreshSocketToken(token);
        handleLoginClose();
        handleForLogin();
        setLoading(false);
        // toast for showing message
        toast.success("Login Successfully", {
          autoClose: 2000,
          position: "top-right",
          toastId: "login-success",
        });
        setLoading(false);
        navigate("/");
      } else {
        setLoading(false);
        console.log("Something went wrong");
        toast.error(data?.message, {
          toastId: "login-error",
          autoClose: 2000,
        });
      }
    },
    onError: (error) => {
      toast.error(error.message);
      setLoading(false);
    },
    onSettled: () => {
      // Reset loading state when the mutation is completed (success or failure)
      setLoading(false);
    },
  });

  function isValidEmailOrUsername(input) {
    // Regular expression pattern for email address
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.com)$/;
    // Regular expression pattern for username
    const usernameRegex = /^[a-zA-Z0-9_-]{8,20}$/;
    // Test input against both email and username regex patterns
    return emailRegex.test(input) || usernameRegex.test(input);
  }

  const formSubmit = (data, e) => {
    e.preventDefault();
    setGameShow(false);
    // Example usage:
    if (isValidEmailOrUsername(data?.email)) {
      mutation.mutate(data);
    } else {
      toast.error("Invalid Username or Email address", {
        toastId: "Email_error",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <ul className="auth-container__fields">
        <li className="auth-container__input-wrapper">
          <label htmlFor="email">Username</label>
          <input
            type="text"
            name="email"
            placeholder="Enter your Username"
            autoComplete="off"
            {...register("email", {
              required: "Username is required",
            })}
            className={`${
              errors?.email?.type === "required" && "dirty invalid"
            }`}
          />
          {errors.email && errors.email.message && (
            <li className="err-text" style={{ textAlign: "start" }}>
              {errors.email.message}
            </li>
          )}
        </li>

        <li className="auth-container__input-wrapper">
          <label htmlFor="Password">Password</label>
          <input
            type={!showPassword ? "password" : "text"}
            name="Password"
            placeholder="Enter your password"
            autoComplete="off"
            {...register("password", {
              required: "Password is required",
              // pattern: {
              //   value:
              //     // eslint-disable-next-line
              //     /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':".,/<>?\|`~])(?=.*\d)(?=.{8,})/,
              //   message:
              //     "Password must contain at least one uppercase letter, one special character, one number, and be at least 8 characters long",
              // },
              pattern: {
                value:
                  // eslint-disable-next-line
                  /^.{6,16}$/,
                message: "The password must have at least 6 characters.",
              },
            })}
            className={`${
              errors?.password && "dirty invalid"
            } input-sub-icon`}
          />
          <IconButton 
            className={`show-password ${errors?.password && "--error"}`}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            {showPassword ? (<Visibility />) : (<VisibilityOff />)}
          </IconButton>
          {errors?.password && errors?.password?.message && (
            <li
              className="err-text"
              field="Password"
              style={{ textAlign: "start" }}
            >
              {errors?.password?.message}
            </li>
          )}
        </li>
        {/* <li className="forgot-password">
          <span
            text_key="LOGIN__FORGOT_YOUR_PASSWORD?"
            onClick={handleForgotShow}
          >
            Forgot Your Password?
          </span>
        </li> */}
        <Button className="auth-container__fields-button" text_key="LOGIN__LOG_IN" type="submit">
          {loading ? (
            <div style={{ zIndex: 99, textAlign: "center" }}>
              <Spinner
                as="span"
                animation="border"
                size="md"
                role="status"
                aria-hidden="true"
                variant="light"
              />
            </div>
          ) : (
            "Log In"
          )}
        </Button>
        {/* <SocialLogin /> */}
      </ul>
    </form>
  );
}

export default LoginForm;
