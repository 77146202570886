import "./style.css";
import React, { useState } from "react";
import Layout from "../layout/layout";

import { ReactComponent as MessageIcon } from "../../assets/images/icons/message.svg";
import { ReactComponent as DepositIcon } from "../../assets/images/icons/deposit.svg";
import { ReactComponent as CreditCardAddIcon } from "../../assets/images/icons/credit-card-add.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/icons/settings.svg";
import { ReactComponent as CloudDownloadIcon } from "../../assets/images/icons/cloud-download.svg";
import { ReactComponent as DollarIcon } from "../../assets/images/icons/dollar.svg";
import { ReactComponent as RescueRingIcon } from "../../assets/images/icons/rescue-ring.svg";
import { ReactComponent as ChevronRightIcon } from "../../assets/images/icons/chevron-rirght.svg";
// import { ReactComponent as ChevronDownIcon } from "../../assets/images/icons/chevron-down.svg";

const Support = () => {
  const [activeItem, setActiveItem] = useState('')

  const isItemActive = item => activeItem === item;

  const handleHelpItemClick = item =>{
    if (activeItem === item) {
      return setActiveItem('');
    }
    return setActiveItem(item);
  };
  return (
    <Layout>
      <div className="support">
        <div className="support-header">
          <p>Support</p>
        </div>
        <div className="support__wrapper">
          <div className="support__menu">
            <button className="support__menu-button --active">
              <p>
                <DepositIcon style={{ width: '20px', height: '16px'}}/>
                Issues with deposit
              </p>
              <ChevronRightIcon />
            </button>
            <button className="support__menu-button">
              <p>
                <CreditCardAddIcon style={{ width: '21px', height: '17px'}}/>
                Issues with withdrawals
              </p>
              <ChevronRightIcon />
            </button>
            <button className="support__menu-button">
              <p>
                <SettingsIcon style={{ width: '20px', height: '20px'}}/>
                Issues with deposit
              </p>
              <ChevronRightIcon />
            </button>
            <button className="support__menu-button">
              <p>
                <CloudDownloadIcon style={{ width: '22px', height: '16px'}}/>
                Issues with website
              </p>
              <ChevronRightIcon />
            </button>
            <button className="support__menu-button">
              <p>
                <DollarIcon />
                Issues with deposit
              </p>
              <ChevronRightIcon />
            </button>
            <button className="support__menu-button --write-to-support">
              <p>
                Write to support
              </p>
              <RescueRingIcon style={{ width: '20px', height: '20px'}}/>
            </button>
          </div>
          <div className="support__content">
            <div className="support__content-header">
              <MessageIcon style={{ width: '20px', height: '19px'}}/>
              <p>FAQ</p>
            </div>
            <div className="support__content-wrapper">
              {categoryTextMock.map((item, index) => (
                <div
                  role="button"
                  onClick={() => handleHelpItemClick(`${item.title}-${index}`)}
                  className={`
                    support__help-item
                    ${isItemActive(`${item.title}-${index}`) ? '--expanded' : '' }
                  `}
                  key={`${item.title}-${index}`}>
                  <p className="support__item-title">
                    {item.title}
                    <ChevronRightIcon />
                  </p>
                    {isItemActive(`${item.title}-${index}`)  && (
                      <p className="support__item-text">
                        {item.helpText}
                      </p>
                    )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      </Layout>
  );
};

export default Support;

const categoryTextMock = [
  {
    title: "Issues with deposit",
    helpText: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
  },
  {
    title: "Issues with deposit",
    helpText: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
  },
  {
    title: "Issues with deposit",
    helpText: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
  },
  {
    title: "Issues with deposit",
    helpText: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
  },
  {
    title: "Issues with deposit",
    helpText: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
  },
  {
    title: "Issues with deposit",
    helpText: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
  },
  
]