import React, { useEffect, useRef, useState } from "react";
import { startselectedGame } from "../../utility/Apis";
import { useParams } from "react-router-dom";
import { useMyContext } from "../context";
// import GameFrameWrapper from "./GameFrameWrapper";
// import { isIOS } from "mobile-device-detect";
// import IosFrame from "./IosFrame";

function IosFrame() {
  const { windowWidth } = useMyContext();
  // const navigate = useNavigate();
  const params = useParams();
  // const handleGameClose = () => {
  //   navigate(-1);
  // };
  const { gameId } = params;
  // const [isFullscreen, setIsFullscreen] = useState(false);
  // const toggleFullscreen = () => {
  //   const element = document.querySelector(".game-info-wrapper");

  //   if (!element) return;
  //   console.log(isFullscreen);
  //   if (isFullscreen && (windowWidth >= 767 || windowHeight >= 767)) {
  //     exitFullscreen();
  //   } else {
  //     enterFullscreen(element);
  //   }
  // };

  // const enterFullscreen = (element) => {
  //   if (element.requestFullscreen) {
  //     element.requestFullscreen();
  //   } else if (element.webkitRequestFullscreen) {
  //     // For iOS
  //     element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
  //   } else if (element.mozRequestFullScreen) {
  //     // For Firefox
  //     element.mozRequestFullScreen();
  //   } else if (element.msRequestFullscreen) {
  //     // For IE/Edge
  //     element.msRequestFullscreen();
  //   } else {
  //     alert("Fullscreen API is not supported in this browser");
  //   }
  //   setIsFullscreen(true);
  // };

  // const exitFullscreen = () => {
  //   if (document.exitFullscreen) {
  //     document.exitFullscreen();
  //   } else if (document.mozCancelFullScreen) {
  //     document.mozCancelFullScreen();
  //   } else if (document.webkitExitFullscreen) {
  //     document.webkitExitFullscreen();
  //   } else if (document.msExitFullscreen) {
  //     document.msExitFullscreen();
  //   }

  //   setIsFullscreen(false);
  // };

  // const [selectedCurrency, setSelectedCurrency] = useState(null);
  // const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // const handleGamePay = () => {
  //   setDropdownOpen(!isDropdownOpen);
  // };

  // const handleCurrencySelect = (currency) => {
  //   console.log("currency===", currency);
  //   setSelectedCurrency(currency);
  //   setDropdownOpen(false);
  // };

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      // setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (window.location.pathname === "/casino/casino") {
      setIsActive(true);
    }
  }, []);

  const [gameLink, setGameLink] = useState("");
  const handleStartGame = async () => {
    try {
      const res = await startselectedGame({
        // selectedCurrency: selectedCurrency,
        gameId: gameId,
        isMobile: windowWidth < 500 ? true : false,
      });
      if (res?.success) {
        setGameLink(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // if (windowWidth <= 767 || windowHeight <= 768) {
    //   toggleFullscreen();
    // }
    window.scrollTo(0, 0);
    if (gameId && !gameLink) {
      handleStartGame();
    }

    // eslint-disable-next-line
  }, [gameId]);

  const [orientation, setOrientation] = useState(window.orientation || 0);

  useEffect(() => {
    const handleOrientationChange = () => {
      if (window.orientation === 90 || window.orientation === -90) {
        setOrientation(90); // Landscape
      } else {
        setOrientation(0); // Portrait
      }
    };

    window.addEventListener("orientationchange", handleOrientationChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  return (
    <>
      {/* {isIOS ? (
        <IosFrame gameLink={gameLink} />
      ) : ( */}
      <div
        className={isActive ? "game-popup miniGames" : "game-popup"}
        // style={{ height: isIOS && "calc(100vh - 100px)" }}
        style={{
          height: window.innerHeight,
          width: orientation === 90 ? "100%" : window.innerWidth,
        }}
      >
        <div className="game-info-wrapper">
          {/* <div className="back-btn-mobile" onClick={handleGameClose}>
            <BackBtn /> <span text_key="CASINO__BACK">Exit</span>
          </div> */}
          <div className="game-wrapper">
            <div className="game">
              <div
                className="iframe-helper"
                // className={isFullscreen ? 'fullscreen-element' : ''}
              >
                {/* {gameLink ? ( */}
                <iframe
                  src={gameLink}
                  frameborder="0"
                  title="Game-play"
                ></iframe>
                {/* ) : (
                <div className="choose-currency"> */}
                {/* <div
                    className="select"
                    name="userCurrencySelect"
                    onClick={handleGamePay}
                  >
                    <div className="select-styled active">
                      {selectedCurrency
                        ? selectedCurrency
                        : "Select Game Currency"}
                    </div>
                    {isDropdownOpen && (
                      <ul className="select-options" ref={dropdownRef}>
                        <li
                          onClick={() => handleCurrencySelect("BTC")}
                          rel="302"
                        >
                          <div className="icon-wrapper">
                            <img src={btc} alt="btc" />
                          </div>
                          BTC
                        </li>
                        <li
                          onClick={() => handleCurrencySelect("ETH")}
                          rel="180"
                        >
                          <div className="icon-wrapper">
                            <img src={eth} alt="eth" />
                          </div>
                          ETH
                        </li>
                        <li
                          onClick={() => handleCurrencySelect("LTC")}
                          rel="202"
                        >
                          <div className="icon-wrapper">
                            <img src={ltc} alt="ltc" />
                          </div>
                          LTC
                        </li>
                      </ul>
                    )}
                  </div> */}
                {/* <div
                    className="btn play-game"
                    text_key="CASINO__PLAY_GAME"
                    onClick={() => handleStartGame()}
                  >
                    Play Game
                  </div>
                </div>
              )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
}

export default IosFrame;
