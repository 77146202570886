import React, { useState } from "react";
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PasswordRecovery = ({ userDetails, handleSetCurrentForm }) => {
  const [passwordError] = useState(true)
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showPassword, setShowPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');

  const [isCodeValid, setIsCodeValid] = useState(false);

  const handleClickConfirm = () => {
    if (isCodeValid) {
      return handleSetCurrentForm('default')
    }
    return setIsCodeValid(true)
  };

  return (
    <>
      {isCodeValid ? (
        <>
          <div className="account-modal__password-content">
            <div className="account-modal__input-wrapper">
              <p>New password</p>
              <input
                type={!showPassword ? "password" : "text"}
                name="new-password"
                value={newPassword}
                placeholder="Enter your new password"
                onChange={({ target: { value }}) => setNewPassword(value)}
              />
              <IconButton 
                className="account-modal__show-password"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? (<VisibilityOff />) : (<Visibility />)}
              </IconButton>
            </div>
          </div>
          <div className="account-modal__password-content">
            <div className={`
              account-modal__input-wrapper ${passwordError ? '--error': ''}`
            }>
              <p>Repeat password</p>
              <input
                type={!showPassword ? "password" : "text"}
                name="confirmarion-code"
                value={repeatPassword}
                placeholder="Repeat password"
                onChange={({ target: { value }}) => setRepeatPassword(value)}
              />
              <IconButton 
                className="account-modal__show-password"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? (<VisibilityOff />) : (<Visibility />)}
              </IconButton>
              {passwordError && (
                <p className="account-modal__password-error">
                  Must contain at least 8 characters
                </p>
              )}
            </div>
          </div>
        </>
      ):(
        <>
          <div className="account-modal__input-wrapper">
            <p>Code</p>
            <input
              type="text"
              name="confirmarion-code"
              value={confirmationCode}
              placeholder="Enter code from your Email"
              onChange={({ target: { value }}) => setConfirmationCode(value)}
            />
          </div>
          <p className="account-modal__help-text">
            A confirmation code has been sent to the email address <b>sok***dio@gmail.com</b> you specified
          </p>
        </>
      )}
      <button
        onClick={handleClickConfirm}
        className="account-modal__confirm-button"
      >
        <p>Confirm</p>
      </button>
    </>
  )
};

export default PasswordRecovery;