import "./style.css";
import React, { useState } from "react";
import { ReactComponent as MegaphoneIcon } from "../../assets/images/icons/megaphone.svg"
import { ReactComponent as ClockIcon } from "../../assets/images/icons/clock.svg"
import { ReactComponent as ClockSpeedIcon } from "../../assets/images/icons/clock-speed.svg"
import { ReactComponent as GoldCoindIcon } from "../../assets/images/icons/gold-coin.svg"
import { ReactComponent as CircleCheckIcon } from "../../assets/images/icons/circle-check.svg"
import { ReactComponent as CalendarIcon } from "../../assets/images/icons/calendar-clock.svg"

import progress1 from "../../assets/images/mock/progress1.png"
import progress2 from "../../assets/images/mock/progress2.png"
import active1 from "../../assets/images/mock/active1.png"
import active2 from "../../assets/images/mock/active2.png"
import active3 from "../../assets/images/mock/active3.png"
import active4 from "../../assets/images/mock/active4.png"
import active5 from "../../assets/images/mock/active5.png"
import active6 from "../../assets/images/mock/active6.png"
import Layout from "../layout/layout";
import PromoDetails from "./PromoDetails";


const DailyPromotion = ({ data = dataMock }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedPromo, setSelectedPromo] = useState(dataMock[0]);
  const progressPromos = data.filter(i => i.progressTotal);
  const activePromos = data.filter(i => !i.progressTotal);

  const handleShowDetails = promo => {
    setShowDetails(true);
    setSelectedPromo(promo)
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
    setSelectedPromo(false);
  }

  return(
    <Layout>
    {(showDetails && selectedPromo) && (
      <PromoDetails
        handleClose={handleCloseDetails}
        isOpen={showDetails && selectedPromo} 
        promo={selectedPromo}
      />
    )}
      <div className="daily-promotion">
        <div className="daily-promotion__header">
          <div className="daily-promotion__title">
            <MegaphoneIcon />
            <p>Daily Promotion</p>
            <div className="daily-promotion__title-badge">
              {data.length}
            </div>
          </div>
          <div className="daily-promotion__button-wrapper">
            <button className="daily-promotion__button --active">
              <ClockIcon />
              <p>Active</p>
            </button>
            <button className="daily-promotion__button">
              <ClockSpeedIcon />
              <p>Finished</p>
            </button>
          </div>
        </div>
        <div className="daily-promotion__content">
          <div className="daily-promotion__progress-wrapper">
            {progressPromos.map((promo, index) => (
              <div
                role="button"
                key={`${promo.title}-${index}`}
                onClick={() => handleShowDetails(promo)}
                style={{ backgroundImage: `url(${promo.bannerUrl})` }}
                className="daily-promotion__progress-item"
              >
                <p className="daily-promotion__progress-title">
                  {promo.title}
                </p>
                <div className="daily-promotion__progress-content">
                  <p>Progress</p>
                  <div className="daily-promotion__content-wrapper">
                    <GoldCoindIcon />
                    <div className="daily-promotion__progressbar-wrapper">
                      <p>{promo.currentProgress}/{promo.progressTotal}</p>
                      <div className="daily-promotion__progressbar">
                        <div style={{ width: `${(promo.progressTotal/100) * promo.currentProgress}%`}}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="daily-promotion__active-promos">
            {activePromos.map((promo, index) => (
              <div
                role="button"
                key={`${promo.title}-${index}`}
                onClick={() => handleShowDetails(promo)}
                style={{ backgroundImage: `url(${promo.bannerUrl})` }}
                className="daily-promotion__promo-item"
              >
                <p  className="daily-promotion__promo-title">
                  {promo.title}
                </p>
                <div className="daily-promotion__status-badge">
                  {promo.completed ? (
                    <>
                      <CircleCheckIcon />
                      <p>Completed</p>
                    </>
                  ): (
                    <>
                      <CalendarIcon />
                      <p>{promo.expiresAt}</p>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DailyPromotion;

const dataMock = [
    {
      title: "Welcome bonus 100%",
      currentProgress: 12,
      progressTotal: 70,
      bannerUrl: progress1,
    },
    {
      title: "30% cashback at casino",
      currentProgress: 12,
      progressTotal: 70,
      bannerUrl: progress2,
    },
    {
      title: "Slots fever",
      completed: true,
      expiresAt: null,
      bannerUrl: active1,
    },
    {
      title: "Free spins for deposit",
      completed: false,
      expiresAt: "18h : 41min : 21sec",
      bannerUrl: active2,
    },
    {
      title: "Jackpot up to $4.000.00",
      completed: false,
      expiresAt: "18h : 41min : 21sec",
      bannerUrl: active3,
    },
    {
      title: "Bgaming gives $5.000",
      completed: false,
      expiresAt: "18h : 41min : 21sec",
      bannerUrl: active4,
    },
    {
      title: "Drops & Wins $100.000.00",
      completed: true,
      expiresAt: null,
      bannerUrl: active5,
    },
    {
      title: "Welcome bonus 100%",
      completed: false,
      expiresAt: "18h : 41min : 21sec",
      bannerUrl: active6,
    },
]