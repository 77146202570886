import { useEffect } from "react";
import "./style.css"
import { Modal as MuiModal } from "@mui/material";

export const  Modal = ({ open, handleClose, children }) => {
  useEffect(() => {
    return () => { 
      window.scroll(0, 0);
    }
  });

  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      className="new-modal"
    >
      <div className="new-modal__content">{children}</div>
    </MuiModal>
  );
};

