import "./layout.css";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import { Drawer } from "@mui/material";

import Auth from "../modals/auth";
import Chat from "../Chat";
import ForgotPopup from "../modals/forgotPopup";
import Header from "../header";
import NewTransection from "../modals/newTransection";
import Notifications from "../Notifications";
import Sidebar from "../sidebar";
import ResetPopup from "../modals/resetPopup";
import Tapbar from "../Tapbar";
import { Modal as NewModal } from "../commomUi/Modal";

import { useMyContext } from "../context";
import UserProgress from "../header/UserProgress";

function Layout({ children, handleShowTransection, headerGamePopup }) {
  const {
    windowWidth,
    showLogin,
    showSignup,
    showForgot,
    showReset,
    forLogin,
    transection,
    activeTab,
    setActiveTab,
    handleLoginClose,
    handleLoginShow,
    handleSignupClose,
    handleSignupShow,
    handleForgotClose,
    handleForgotShow,
    handleForLogin,
    handleHideTransection,
    handleResetShow,
    handleResetClose,
    userDetails,
  } = useMyContext();
  const dataLocation = useLocation() || "";
  const [resetToken, stateResetToken] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false)
  let spltedStr = dataLocation?.search?.split("=");
  let urlToken = spltedStr[1];

  useEffect(() => {
    if (spltedStr && spltedStr[0]?.split("-")?.includes("token")) {
      stateResetToken(urlToken);
      handleResetShow();
    }
    // eslint-disable-next-line
  }, [urlToken]);

  useEffect(() => {
    localStorage.setItem("loginState", JSON.stringify(forLogin));
  }, [forLogin]);

  return (
    <div className="wrapper">
      {showSidebar && windowWidth <= 991  && (
        <Drawer
          className="drawer"
          open={showSidebar}
          onClose={() => setShowSidebar(false)}
        >
          <div className="drawer__sidebar">
            {userDetails?.agentId && (
              <Link 
                to="/account/profile"
                className="drawer__profile">
                <UserProgress />
                <p className="drawer__profile-details">
                  <p className="drawer__profile-text">
                    {userDetails?.userName}
                  </p>
                  <p className="drawer__profile-text --subtitle">
                    ID: {userDetails?.agentId}
                  </p>
                </p>
              </Link>
            )}
            <Sidebar setShowSidebar={setShowSidebar} />
          </div>
        </Drawer>
      )}
      
      <Header
        handleShowTransection={handleShowTransection}
        headerGamePopup={headerGamePopup}
        showNotifications={showNotifications}
        setShowNotifications={setShowNotifications}
      />
      {showNotifications && (
        <Notifications
          isOpen={showNotifications}
          handleClose={() => setShowNotifications(false)}
        />
      )}
      {windowWidth >= 991 && (<Sidebar setShowSidebar={setShowSidebar} />)}
      <main
        className="middle"
        id="middle-content"
      >
        {children}
      </main>
      <Chat />
      {windowWidth <= 991 && (
        <Tapbar
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
        />
      )}
      {/* {windowWidth <= 991 && <MenuNav />} */}
      {/* <Footer /> */}

      <NewModal
        open={showLogin || showSignup}
        onClose={handleLoginClose}
      >
        <Auth
          showLogin={showLogin}
          showSignup={showSignup}
          handleLoginClose={handleLoginClose}
          handleSignupClose={handleSignupClose}
          handleLoginShow={handleLoginShow}
          handleSignupShow={handleSignupShow}
          handleForgotShow={handleForgotShow}
          handleForLogin={handleForLogin}
        />
      </NewModal>
      <Modal
        show={showForgot}
        onHide={handleForgotClose}
        centered
        className="jackbit-modal"
      >
        <Modal.Body>
          <ForgotPopup handleForgotClose={handleForgotClose} />
        </Modal.Body>
      </Modal>

      {/* Reset Password popup */}

      <Modal
        show={showReset}
        onHide={handleResetClose}
        centered
        className="jackbit-modal"
      >
        <Modal.Body>
          <ResetPopup
            handleResetClose={handleResetClose}
            resetToken={resetToken}
          />
        </Modal.Body>
      </Modal>

      <NewModal
        open={transection}
        onClose={handleHideTransection}
      >
        <NewTransection 
          initialTab={transection}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          handleHideTransection={handleHideTransection}
        />
      </NewModal>
    </div>
  );
}

export default Layout;
