import "./style.css";
import React, { useState } from 'react';

import { ReactComponent as ArrowDownIcon } from "../../../assets/images/icons/chevron-down.svg"
import { ReactComponent as ArrowTopIcon } from "../../../assets/images/icons/chevron-top.svg"
import { ReactComponent as GoldCoinIcon } from '../../../assets/images/icons/gold-coin.svg';
import { ReactComponent as CopyIcon } from "../../../assets/images/icons/copy.svg"
import { ReactComponent as ArrowIcon } from "../../../assets/images/icons/arrow.svg"
import { ReactComponent as PeopleAddIcon } from "../../../assets/images/icons/people-add.svg"
import { ReactComponent as SlotsIcon } from "../../../assets/images/icons/slots1.svg"
import { ReactComponent as MoneyBagIcon } from "../../../assets/images/icons/money-bag.svg"
import { ReactComponent as TrashCanIcon } from "../../../assets/images/icons/trash-can.svg"
import { ReactComponent as LockIcon } from '../../../assets/images/icons/lock.svg';

const RenderCampaignItem = ({ campaign, index, handleExpand }) => (
  <li
    onClick={() => handleExpand(index)} 
    className="campaigns__list-item" key={`${campaign.name}+${index}`}
  >
    <div className="campaigns__col-item">
      <p>{campaign.date}</p>
    </div>
    <div className="campaigns__col-item">
      <p>{campaign.name}</p>
    </div>
    <div className="campaigns__col-item --white-text">
      <GoldCoinIcon />
      <p>{campaign.totalEarn}</p>
    </div>
    <div className="campaigns__col-item ">
      <p>{campaign.campaignLink}</p>
      <CopyIcon />
    </div>
    <div className="campaigns__col-item --justify-center">
      <p>{campaign.percentage}%</p>
    </div>
    <div className="campaigns__col-item">
      <ArrowDownIcon />
    </div>
  </li>
);

const Campaigns = () => {
  const [expadedItem, setExpandedItem] = useState(0)
  
  const handleExpand = id => {
    setExpandedItem(id)
  };
  return (
    <div className="campaigns">
      <div className="campaigns__header">
        <div className="campaigns__title">
          <p>Your campaigns</p>
          <div className="campaigns__header-badge">{dataMock.length}</div>
        </div>
        <div className="campaigns__buttons-wrapper">
          <button
            className="campaigns__header-button --active"
          >
            All
          </button>
          <button
            className="campaigns__header-button"
          >
            Month
          </button>
          <button
            className="campaigns__header-button"
          >
            Year
          </button>
        </div>
      </div>
      <div className="campaigns__list-wrapper">
        <ul className="campaigns__list">
          <li className="campaigns__list-header">
            <div className="campaigns__col-item">
              <p>Date</p>
            </div>
            <div className="campaigns__col-item --justify-center">
              <p>Name</p>
            </div>
            <div className="campaigns__col-item --justify-center">
              <p>Total earn</p>
            </div>
            <div className="campaigns__col-item --justify-center">
              <p>Percentage</p>
            </div>
            <div/>
          </li>
          {dataMock.map((campaign, index) => 
              expadedItem === index ? (
                <li className="campaigns__expanded-item">
                  <div className="campaigns__expanded-top">
                    <div className="campaigns__coll-wrapper">
                      <div className="campaigns__col-item">
                        <div className="campaigns__top-subitem">
                          <p className="campaigns__mobile-header">
                            Date
                          </p>
                          <p>{campaign.date}</p>
                        </div>
                      </div>
                      <div className="campaigns__col-item --justify-center">
                        <div className="campaigns__top-subitem">
                          <p className="campaigns__mobile-header">
                            Name
                          </p>
                          <p>{campaign.name}</p>
                        </div>
                      </div>
                    </div>
                    <div className="campaigns__coll-wrapper">
                      <div className="campaigns__col-item --justify-center --white-text">
                        <div className="campaigns__top-subitem">
                          <p className="campaigns__mobile-header">
                            Total earn
                          </p>
                          <p><GoldCoinIcon /> {campaign.totalEarn}</p>
                        </div>
                      </div>
                      <div className="campaigns__col-item --justify-center">
                        <div className="campaigns__top-subitem">
                          <p className="campaigns__mobile-header">
                            Percentage
                          </p>
                          <p>{campaign.percentage}%</p>
                        </div>
                      </div>
                    </div>
                    <div className="campaigns__col-item">
                      <ArrowTopIcon />
                    </div>
                  </div>
                  <div className="campaigns__expanded-bottom">
                    <div className="campaigns__bottom-item">
                      <div className="campaigns__coll-wrapper">
                        <div className="campaigns__col-item --vertical">
                          <p>Total  clicks</p>
                          <div className="campaigns__col-subitem">
                            <ArrowIcon  style={{ width: '13px', height: '13px' }}/>
                            {campaign.totalClicks}
                          </div>
                        </div>
                        <div className="campaigns__col-item --vertical --justify-center">
                          <p>Total  referrals</p>
                          <div className="campaigns__col-subitem">
                            <PeopleAddIcon style={{ width: '14px', height: '13px' }}/>
                            {campaign.totalReferrals}
                          </div>
                        </div>
                      </div>
                      <div className="campaigns__coll-wrapper">
                        <div className="campaigns__col-item --vertical --justify-center">
                          <p>Total bets amount</p>
                          <div className="campaigns__col-subitem">
                            <SlotsIcon style={{ width: '15px', height: '13px' }}/>
                            {campaign.totalbetsAmount}
                          </div>
                        </div>
                        <div className="campaigns__col-item --vertical">
                          <p>Total winnings</p>
                          <div className="campaigns__col-subitem">
                            <MoneyBagIcon style={{ width: '12px', height: '13px' }}/>
                            {campaign.totalWinnings}
                          </div>
                        </div>
                      </div>
                      <div className="campaigns__col-item">
                        <TrashCanIcon style={{ width: '20px', height: '20px' }}/>
                      </div>
                    </div>
                    <div className="campaigns__divider" />
                    <div className="campaigns__inputs-wrapper">
                      <div className="campaigns__input-item">
                        <label>Campaign Name</label>
                        <input
                          className="campaigns__input"
                          placeholder={campaign.name}
                        />
                        <LockIcon />
                      </div>
                      <div className="campaigns__input-item">
                        <label>Campaign Link</label>
                        <input
                          className="campaigns__input"
                          placeholder={campaign.link}
                        />
                        <CopyIcon />
                      </div>
                      <div className="campaigns__input-item">
                        <label>Campaign Code</label>
                        <input
                          className="campaigns__input"
                          placeholder={campaign.campaignCode}
                        />
                        <CopyIcon />
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                <RenderCampaignItem
                  index={index}
                  campaign={campaign}
                  handleExpand={handleExpand}
                />)
          )}
        </ul>
      </div>
    </div>
  );
};

export default Campaigns;

const dataMock = [ 
  {
    date: "07.01.25 12:44",
    name: "Messai",
    totalEarn: "1,156.00",
    percentage: 10,
    totalClicks: 19,
    totalReferrals: 19,
    totalbetsAmount: 19,
    totalWinnings: "1,156.00",
    campaignLink: "https://cryptoroll123",
    campaignCode: "cryptoroll123",
  },
  {
    date: "07.01.25 12:44",
    name: "Default",
    totalEarn: "1,156.00",
    percentage: 10,
    totalClicks: 19,
    totalReferrals: 19,
    totalbetsAmount: 19,
    totalWinnings: "1,156.00",
    campaignLink: "https://cryptoroll123",
    campaignCode: "cryptoroll123",
  },
  {
    date: "07.01.25 12:44",
    name: "Default",
    totalEarn: "1,156.00",
    percentage: 10,
    totalClicks: 19,
    totalReferrals: 19,
    totalbetsAmount: 19,
    totalWinnings: "1,156.00",
    campaignLink: "https://cryptoroll123",
    campaignCode: "cryptoroll123",
  },
  {
    date: "07.01.25 12:44",
    name: "Default",
    totalEarn: "1,156.00",
    percentage: 10,
    totalClicks: 19,
    totalReferrals: 19,
    totalbetsAmount: 19,
    totalWinnings: "1,156.00",
    campaignLink: "https://cryptoroll123",
    campaignCode: "cryptoroll123",
  },
  {
    date: "07.01.25 12:44",
    name: "Default",
    totalEarn: "1,156.00",
    percentage: 10,
    totalClicks: 19,
    totalReferrals: 19,
    totalbetsAmount: 19,
    totalWinnings: "1,156.00",
    campaignLink: "https://cryptoroll123",
    campaignCode: "cryptoroll123",
  },
  {
    date: "07.01.25 12:44",
    name: "Default",
    totalEarn: "1,156.00",
    percentage: 10,
    totalClicks: 19,
    totalReferrals: 19,
    totalbetsAmount: 19,
    totalWinnings: "1,156.00",
    campaignLink: "https://cryptoroll123",
    campaignCode: "cryptoroll123",
  },
  {
    date: "07.01.25 12:44",
    name: "Default",
    totalEarn: "1,156.00",
    percentage: 10,
    totalClicks: 19,
    totalReferrals: 19,
    totalbetsAmount: 19,
    totalWinnings: "1,156.00",
    campaignLink: "https://cryptoroll123",
    campaignCode: "cryptoroll123",
  },
]