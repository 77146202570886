import "./style.css";
import React from "react";
import { Close } from "@mui/icons-material";
import { ReactComponent as DepositIcon } from "../../assets/images/notifications/deposit.svg"

const Notifications = ({ handleClose, isOpen }) => {

  const renderNotificationItem = (itemData, index) => (
    <>
      <button className="notifications__item" key={`${itemData.name}-${index}`}>
        <div className="notifications__item-icon">
          <DepositIcon />
        </div>
        <div className="notifications__item-wrapper">
          <p className="notifications__item-title">
            {itemData.title}
          </p>
          <p className="notifications__item-text">
            {itemData.text}
          </p>
        </div>
      </button>
      {mockNotifications.length -1 === index ? '' :  (
        <div className="notifications__divider" />
      )}
    </>
  );

  return (
    <div className={`notifications`}>
      <div className="notifications__header">
        <p>Notifications</p>
        <button onClick={() => handleClose()}>
          <Close />
        </button>
      </div>
      <div className="notifications_content">
        {mockNotifications.map((item, index) => renderNotificationItem(item, index))}
      </div>
      <button className="notification__read-all">
        <div>Mark all as read</div>
      </button>
    </div>
  );
}

export default Notifications

const mockNotifications = [
  {
    title: 'Deposit pending',
    text: 'Your deposit  is registered and awaiting confirmation',
  },
  {
    title: 'Deposit pending',
    text: 'Your deposit  is registered and awaiting confirmation',
  }
]