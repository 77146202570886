import React, { useEffect, useState } from 'react'
import './style.css'
import { useLocation, useNavigate } from 'react-router-dom'
// import MegaphoneIcon from '../../assets/images/icons/megaphone.svg'
// import MedalIcon from '../../assets/images/icons/medal.svg'
// import RouletteIcon from '../../assets/images/icons/roulette.svg'
// import HeartIcon from '../../assets/images/icons/heart.svg'
import GamepadIcon from '../../assets/images/icons/gamepad.svg'
import SlotsIcon from '../../assets/images/icons/slots1.svg'
// import TicketIcon from '../../assets/images/icons/ticket.svg'
// import PersonsIcon from '../../assets/images/icons/persons.svg'
// import TrophyIcon from '../../assets/images/icons/trophy.svg'
import { ReactComponent as StarIcon} from '../../assets/images/icons/star.svg'
import { useMyContext } from '../context'
import { jwtDecode } from 'jwt-decode'
import Promocode from '../Promocode'
import LuckyWheel from '../LuckyWheel'

function Sidebar({ setShowSidebar }) {
  const {
    gameSubCategory,
    loading,
    activeCategory,
    setActiveCategory,
    setShowLobbyGames
  } = useMyContext()
  const navigate = useNavigate()
  const location = useLocation()
  const currPath = location.pathname
  const token = localStorage.getItem('token')
  const shoudlNavigate = currPath !== "/"
  const [showPromocode, setShowPromocode] = useState(false);
  const [showLuckyWheel, setShowLuckyWheel] = useState(false);


  useEffect(() => {
    const accessToken = localStorage.getItem('token')
    if (accessToken) {
      const decodedToken = jwtDecode(accessToken)
      const expirationTimeInSeconds = decodedToken.exp || 0
      const currentTimeInSeconds = Math.floor(Date.now() / 1000)
      // const timeLeft = expirationTimeInSeconds - currentTimeInSeconds;
      // const hours = Math.floor(timeLeft / 3600);
      // const minutes = Math.floor((timeLeft % 3600) / 60);
      // const seconds = timeLeft % 60;

      if (expirationTimeInSeconds <= currentTimeInSeconds) {
        localStorage.clear()
        window.location.reload()
      }
    }
    // eslint-disable-next-line
  }, [loading, token, currPath])

  return (
    <nav id="sidebar" className={`sidebar-menu`}>
      <div className="sections-wrapper">
        <section className="menu">
          <ul>
            <ul className="sidebar-menu__list">
              {/* <li className="sidebar-menu__list-item">
                <img src={MegaphoneIcon} alt="megaphone" />
                <p>Best games</p>
                <div className="sidebar-menu__for-you-badge">
                  <img src={HeartIcon} alt="heart" />
                  <p>For You</p>
                </div>
              </li> */}
              <li
                className={`sidebar-menu__list-item ${
                    activeCategory === 'favorite' && currPath === "/" ? '--active' : ''
                  } 
                `}
                onClick={() =>{
                  if (shoudlNavigate) navigate("/")
                  setActiveCategory('favorite')
                  setShowLobbyGames(false)
                  setShowSidebar(false)
                } }
              >
                <StarIcon alt="star" />
                <p>Favourites</p>
              </li>
            </ul>
            {/* <ul className="sidebar-menu__list">
              <div className="sidebar-menu__list-title">
                REWARDS
              </div>
              <li 
                className={`sidebar-menu__list-item 
                  ${currPath === '/daily-promotion' ? '--active' : '' }`}
                onClick={() => navigate("/daily-promotion")}
              >
                <img src={MegaphoneIcon} alt="megaphone" />
                <p>Daily promotion</p>
              </li>
              <li className="sidebar-menu__list-item">
                <img src={MedalIcon} alt="medal" />
                <p>Tournament</p>
                <div className="sidebar-menu__badge">
                  16
                </div>
              </li>
              <li
                onClick={() => setShowLuckyWheel(true)}
                className="sidebar-menu__list-item"
              >
                <img src={RouletteIcon} alt="roulette" />
                <p>Lucky wheel</p>
              </li>
            </ul> */}
            <ul className="sidebar-menu__list">
              <div className="sidebar-menu__list-title">
                GAMES
              </div>
              <li
                className={`sidebar-menu__list-item ${
                  activeCategory === 'lobby' && currPath === "/" ? '--active' : ''
                }`}
                text_key="MENU__SLOT"
                onClick={() => {
                  if (shoudlNavigate) navigate("/")
                  setActiveCategory('lobby')
                  setShowLobbyGames(true)
                }}
              >
                <img src={GamepadIcon} alt="gamepad" />
                <p>All Games</p>
              </li>
              {gameSubCategory &&
                gameSubCategory
                  ?.sort((a, b) => {
                    // If a category doesn't have a rank, place it at the top
                    if (!a.category_rank) return -1
                    if (!b.category_rank) return 1
                    // Otherwise, sort by rank
                    return a.category_rank - b.category_rank
                  })
                  ?.map((el) => (
                    <li
                      category={el?.name}
                      key={el?.masterGameSubCategoryId}
                      className={`sidebar-menu__list-item ${
                        activeCategory === el?.masterGameSubCategoryId && currPath === "/" ? '--active' :  ""
                      }`}
                      onClick={() =>{
                        if (shoudlNavigate) navigate("/")
                        setActiveCategory(el?.masterGameSubCategoryId)
                        setShowLobbyGames(false)
                        setShowSidebar(false)
                      }}
                    >
                      <img src={SlotsIcon} alt="slots" />
                      <p>{el?.name}</p>
                    </li>
                  ))}
            </ul >
            {/* <ul className="sidebar-menu__list">
              <div className="sidebar-menu__list-title">
                BENEFITS
              </div>
              <li
                onClick={() => { setShowPromocode(true) }}
                className="sidebar-menu__list-item"
                >
                <img src={TicketIcon} alt="ticket" />
                <p>Promocode</p>
              </li>
              <li 
                className={`sidebar-menu__list-item 
                  ${currPath === '/referral-award' ? '--active' : '' }`}
                onClick={() => navigate("/referral-award")}
                >
                <img src={PersonsIcon} alt="persons" />
                <p>Referral award</p>
              </li>
              <li 
                className={`sidebar-menu__list-item 
                  ${currPath === '/leaderboard' ? '--active' : '' }`}
                onClick={() => navigate("/leaderboard")}
                >
                <img src={TrophyIcon} alt="trophy" />
                <p>Leaderboard</p>
              </li>
            </ul> */}
            {/* <li
              className="icon promo icon-shadow"
              onClick={handleMenuClickClose}
            >
              <Link to="/static/promos" text_key="MENU__PROMO">
                PROMOTIONS
              </Link>
            </li> */}
            {/* <li
              className="static icon support icon-shadow"
              // onClick="LC_API.open_chat_window()"
            >
              <Link to="/" text_key="MENU__LIVE_SUPPORT " className="fontStyle">
                Live chat 24/7
              </Link>
            </li> */}
            {/* <li className="static icon affiliate icon-shadow">
              <Link
                className="link fontStyle"
                // to="/en/static/affiliate"
                to="/"
                text_key="MENU__AFFILIATE"
              >
                Affiliate
              </Link>
            </li> */}
          </ul>
        </section>
      </div>
      {showLuckyWheel  && (
        <LuckyWheel
          isOpen={showLuckyWheel}
          handleClose={() =>  setShowLuckyWheel(false)}
        />
      )}
      {showPromocode && (
        <Promocode
          isOpen={showPromocode}
          handleClose={() => setShowPromocode(false)}
        />
      )}
    </nav>
  )
}

export default Sidebar
