import "./style.css"
import React, { useState } from 'react';
import { Modal } from '../../commomUi/Modal';
import { Close } from '@mui/icons-material';

import { useMyContext } from '../../context';

import { ReactComponent as SettingsIcon } from  "../../../assets/images/icons/settings.svg"
import { ReactComponent as EmailSettingsIcon } from  "../../../assets/images/icons/email-settings.svg"
import { ReactComponent as ShieldIcon } from  "../../../assets/images/icons/shield-keyhole.svg"
import DefaultForm from "./DefaultForm";
import ConfirmEmail from "./ConfirmEmail";
import PasswordRecovery from "./PasswordRecovery";


const Settings = ({ isOpen, handleClose}) => {
  const {
    userDetails,
    showBalance,
    setShowBalance
  } = useMyContext();
  const [currentForm, setCurrentForm] = useState('default')
  
  const RenderHeaderTitle = () => {
    if (currentForm === 'confirm-email') {
      return (
        <>
          <EmailSettingsIcon />
          <p>Email confirmation</p>
        </>
      );
    }
    if (currentForm === 'password-recovery') {
      return (
        <>
          <ShieldIcon />
          <p>Password recovery</p>
        </>
      );
    }
    return (
      <>
        <SettingsIcon />
        <p>Settings</p>
      </>
    );
  };

  return (
    <Modal open={isOpen}>
      <div className="account-modal">
        <div className="account-modal__header">
          <div className="account-modal__header-title">
            <RenderHeaderTitle />
          </div>
           <button
            onClick={handleClose} 
            className="promocode__close-button"
            >
            <Close />
          </button>
        </div>
        <div className="account-modal__content">
          {currentForm === 'confirm-email' && (
            <ConfirmEmail
              userDetails={userDetails}
              handleSetCurrentForm={setCurrentForm}
            />
          )}
          {currentForm === 'password-recovery' && (
            <PasswordRecovery
              userDetails={userDetails}
              handleSetCurrentForm={setCurrentForm}
            />
          )}
          {currentForm === 'default' && (
            <DefaultForm
              userDetails={userDetails}
              handleSetCurrentForm={setCurrentForm}
              showBalance={showBalance}
              setShowBalance={setShowBalance}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Settings;
