import React, { useState } from "react";
import { toast } from "react-toastify";
import { Close } from "@mui/icons-material";
import {
  // NetWorkData,
  coinData,
  // currency,
} from "../../../utility/transectionFormDetails.js";
import "./style.css";
import {
  // checkOut,
  getTransectonDetails,
  withDrawAmount,
} from "../../../utility/Apis.js";
import { useMyContext } from "../../context/index.js";
// import { ReactComponent as DepositIcon } from "../../../assets/images/icons/deposit.svg"
// import { ReactComponent as DepositActiveIcon } from "../../../assets/images/icons/deposit-active.svg"
// import { ReactComponent as WithdrawIcon } from "../../../assets/images/icons/withdraw.svg"
// import { ReactComponent as WithdrawActiveIcon } from "../../../assets/images/icons/withdraw-active.svg"
import { ReactComponent as HistoryIcon } from "../../../assets/images/icons/history.svg"
import { ReactComponent as HistoryActiveIcon } from "../../../assets/images/icons/history-active.svg"
// import QRCodeGenerator from "../../utility/CustomQrCodeGenerator.jsx";
import DepositForm from "./DepositForm.jsx";
import WithdrawForm from "./WithdramForm.jsx";
import History from "./History.jsx";

function NewTransection({ handleHideTransection }) {
  let [loading, setLoading] = useState(false);
  const { userDetails, setUserDetails } = useMyContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isNetwork, setIsNetwork] = useState(false);
  // const [isBuy, setIsBuy] = useState(false);
  const [isPay, setIsPay] = useState(false);
  const [isWithdraw, setIsWithdraw] = useState(false);
  const [isWithdrawNetwork, setIsWithdrawNetwork] = useState(false);
  const [walletActiveTab, setWalletActiveTab] = useState("history");
  // state for handle Account Currency data and images
  const [name, setName] = useState("Select Account Currency");
  const [image, setImage] = useState(null);
  // state for handle Account Currency withdraw data and images
  const [withdrawname, setwithdrawName] = useState("Select Account Currency");
  const [withdrawimage, setwithdrawImage] = useState(null);
  const [withdrawsym, setWithdrawsym] = useState("");
  // eslint-disable-next-line
  const [networkValue, setIsNetworkValue] = useState("Choose Network");
  
  const [addressValue, setAddressValue] = useState(null);
  
  //manage currency data
  // eslint-disable-next-line
  const [currencyName, setCurrencyName] = useState("Select");
  // eslint-disable-next-line
  const [currencyImage, setCurrencyImage] = useState(null);
  // eslint-disable-next-line
  const [currencyValue, setCurrencyValue] = useState("");
  const [withDrawData, setWithdrawData] = useState({
    amount: "",
    address: "",
  });
  const isDepositActive = walletActiveTab === "deposit";
  const isWithdrawActive = walletActiveTab === "withdraw"
  const isHistoryActive = walletActiveTab === "history"


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const NetworktoggleDropdown = () => {
    setIsNetwork(!isNetwork);
  };
  console.log(NetworktoggleDropdown);

  // const toggleBuyDropdown = () => {
  //   setIsBuy(!isBuy);
  // };

  // eslint-disable-next-line
  const togglePayDropdown = () => {
    setIsPay(!isPay);
  };

  const toggleWithdrawDropdown = () => {
    setIsWithdraw(!isWithdraw);
  };

  const toggleNetworkDropdown = () => {
    setIsWithdrawNetwork(!isWithdrawNetwork);
  };
  console.log(toggleNetworkDropdown);

  const getName = (data, value) => {
    // console.log("data , value------------>", data, value);

    if (value.value === "deposit") {
      setName(`${data.coin}`);
      setImage(data?.icon);
    } else if (value.value === "withdraw") {
      setwithdrawName(`${data.coin}`);
      setwithdrawImage(data?.icon);
      setWithdrawsym(data?.sym);
    } else if (value.value === "buyCrypto") {
      setCurrencyName(data.name);
      setCurrencyImage(data?.icon);
    }
  };
  // console.log("withdrawsym============?>", withdrawsym);

  // ======Deposit and withdrawal api call here================================
  const callApiDeposit = async (data, value) => {
    if (value.value === "deposit") {
      setIsNetworkValue(data.coin);
      try {
        setLoading(true);
        const res = await getTransectonDetails({ currencyCode: data.sym });
        // console.log("res==============>", res);
        if (res?.walletDetail) {
          setLoading(false);
          setAddressValue(res?.walletDetail?.address);
        } else {
          toast.error(res?.message, {
            autoClose: 2000,
            position: "top-right",
            toastId: "address-success",
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else if (value.value === "withdraw") {
      if (withDrawData.amount <= 0)
        return toast.error("Invalid Amount", {
          toastId: "Invalid-amount",
        });
      const newData = {
        amount: withDrawData.amount,
        address: withDrawData.address,
        currencyCode: withdrawsym,
      };
      try {
        const res = await withDrawAmount(newData);
        if (res?.status === 200) {
          toast.success("withdraw successfull", {
            toastId: "withdraw_amount",
          });
          setUserDetails({
            ...userDetails,
            cryptoWallet: {
              ...userDetails.cryptoWallet,
              [res?.blockchain]: res?.currentBalance,
            },
          });
        } else {
          toast.error(res?.message, {
            toastId: "withdraw_error",
          });
        }
        // console.log("res============>", res);
      } catch (error) {
        console.log(error);
      }
    }
  };

  //open payment browser like modal

  // function openModal(url, windowName, width, height) {
  //   const left = window.innerWidth / 2 - width / 2;
  //   const top = window.innerHeight / 2 - height / 2;

  //   // Open a new window with specified features
  //   const modalWindow = window.open(
  //     url,
  //     windowName,
  //     `width=${width},height=${height},left=${left},top=${top},modal=yes,centerscreen=yes,chrome=yes`
  //   );

  //   return modalWindow;
  // }

  // buy crypto api call here===========================
  // const handleBuyCrypto = async () => {
  //   if (!currencyValue) return;
  //   try {
  //     const res = await checkOut({
  //       amount: currencyValue,
  //       currency: currencyName,
  //     });
  //     // console.log("res======>", res);
  //     if (res?.status === 200) {
  //       // window.open(res?.data?.hosted_url);
  //       const modalWindow = openModal(
  //         res?.data?.hosted_url,
  //         "ModalWindow",
  //         600,
  //         800
  //       );
  //       console.log(modalWindow);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div className="transection-dialog">
      <div className="transection-dialog__header">
        <div className="transection-dialog__header-wrapper">
          {/* <button
            onClick={() => setWalletActiveTab("deposit")}
            className={`transection-dialog__header-button ${isDepositActive ? '--active' : ''}`}
          >
            {isDepositActive ? (<DepositActiveIcon />) : (<DepositIcon />)}
            <p>Deposit</p>
            <div className="transection-dialog__header-active-indicator"/>
          </button>
          <button
            onClick={() => setWalletActiveTab("withdraw")}
            className={`transection-dialog__header-button ${isWithdrawActive ? '--active' : ''}`}
          >
            {isWithdrawActive ? (<WithdrawActiveIcon />) : (<WithdrawIcon />)}
            <p>Withdraw</p>
            <div className="transection-dialog__header-active-indicator"/>
          </button> */}
          <button
            onClick={() => setWalletActiveTab("history")}
            className={`transection-dialog__header-button ${isHistoryActive ? '--active' : ''}`}
          >
            {isHistoryActive ? (<HistoryActiveIcon />) : (<HistoryIcon />)}
            <p>History</p>
            <div className="transection-dialog__header-active-indicator"/>
          </button>
        </div>
        <button
          onClick={handleHideTransection}
          className="transection-dialog__header-close"
        >
          <Close />
        </button>
      </div>
      <div className="transection-dialog__content">
        {isDepositActive && (
          <DepositForm 
            image={image}
            name={name}
            isOpen={isOpen}
            loading={loading}
            coinData={coinData}
            addressValue={addressValue}
            getName={getName}
            callApiDeposit={callApiDeposit}
            toggleDropdown={toggleDropdown}
          />
        )}
        {isWithdrawActive && (
          <WithdrawForm
            coinData={coinData}
            isWithdraw={isWithdraw}
            withDrawData={withDrawData}
            withdrawname={withdrawname}
            withdrawimage={withdrawimage}
            getName={getName}
            callApiDeposit={callApiDeposit}
            setWithdrawData={setWithdrawData}
            toggleWithdrawDropdown={toggleWithdrawDropdown}
          />
        )}
        {isHistoryActive && (
          <History />
        )}
      </div>
    </div>
  );
}

export default NewTransection;
