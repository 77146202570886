import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Favorite, FavoriteBorder } from "@mui/icons-material";

import play from "../../assets/images/icons/play.svg";
import { useMyContext } from "../context";
import { useNavigate } from "react-router-dom";
import { useChangeUserFavorites } from "../../hooks/useChangeUserFavorites";
import { Tooltip } from "@mui/material";
// import notFound from "../../assets/images/casinogame/not-found.png";

function GameCard({
  handleGameShow,
  allGamesData: gamesData,
  loading,
  getPageNumber,
  page,
  queriedCount,
  getGame,
  searchvalue,
  gameLoader,
}) {
  const navigate = useNavigate();
  const { setIsGamePlaying, favorites } = useMyContext();
  const [allGamesShown] = useState(false);
  const { changeUserFavorites } = useChangeUserFavorites();

  useEffect(() => {
    const middle = document.getElementById("middle-content")
    middle.scrollTo(0,0)
  }, [])

  const handleChange = (game) => {
    if (game) navigate(`/game/${game?.masterCasinoGameId}`);
    const scrollPos = window.pageYOffset;
    sessionStorage.setItem("scrollPosition", `${scrollPos}`);
    sessionStorage.setItem("pageSize", `${(page - 1) * 30}`);
    sessionStorage.setItem("page", page);
  };

  const handleClickFavorite = async (gameId) => changeUserFavorites(gameId);
  
  return (
    <>
      <div className="games-wrapper">
        {gameLoader && (
          <div
            style={{
              zIndex: 99,
              // textAlign: "center",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "20px",
              width: "100%",
            }}
          >
            <Spinner
              as="span"
              animation="border"
              size="md"
              role="status"
              aria-hidden="true"
              variant="light"
              style={{ height: "50px", width: "50px" }}
            />
          </div>
        )}
        {!gameLoader ? (
          gamesData.length > 0 ? (
            <ul className={`games`}>
              {gamesData?.map((game, index) => (
                <li
                  key={game.masterCasinoGameId}
                  gameid={game?.masterCasinoGameId}
                  category={game?.masterGameSubCategoryId}
                >
                  <div className="bg" onClick={() => getGame(game)}>
                    <img src={game?.thumbnailUrl} alt="category-games" />

                    <div className="slot-hover">
                      <span onClick={handleGameShow}>
                        <img
                          onClick={() => {
                            handleChange(game);
                            setIsGamePlaying(true);
                          }}
                          className="play"
                          src={play}
                          alt="play icon"
                        />
                      </span>
                      <div className="star-wrapper">
                        {favorites?.length > 0 &&
                          favorites?.includes(game?.masterCasinoGameId)
                            ? (
                              <Favorite
                                onClick={() =>
                                  handleClickFavorite(game?.masterCasinoGameId)
                                }
                              />
                            ): (
                              <FavoriteBorder 
                                onClick={() =>
                                  handleClickFavorite(game?.masterCasinoGameId)
                                }
                              />
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="game__name-wrapper">
                    <Tooltip
                      followCursor
                      enterDelay={600}
                      title={game?.name}
                    >
                      <p>
                        {game?.name}
                      </p>
                    </Tooltip>
                    <p>
                      {game?.MasterGameSubCategory?.name}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          ) : searchvalue ? (
            <div className="gamer-card__no-result">
              <div className="text-center">
                <p>No results</p>
              </div>
            </div>
          ) : (
            <div className="gamer-card__no-result">
              <div className="text-center">
                <p>No games</p>
              </div>
            </div>
          )
        ) : null}

        {loading ? (
          <div
            style={{
              zIndex: 99,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "20px",
            }}
          >
            <Spinner
              as="span"
              animation="border"
              size="md"
              role="status"
              aria-hidden="true"
              variant="light"
              style={{ height: "50px", width: "50px" }}
            />
          </div>
        ) : (
          <div className="game-card__footer">
            <div className="game-card__footer-text">
              <p>{gamesData?.length}</p>
              &nbsp;
              <p>{`/ ${queriedCount}`}</p>
            </div>
            {!allGamesShown &&
              gamesData?.length !== queriedCount &&
              gamesData?.length <= queriedCount && (
                <button
                  className="game-card__footer-button"
                  onClick={() => {
                    getPageNumber(page + 1);
                  }}
                  text_key="CASINO__LOAD_MORE"
                >
                  Show more
                </button>
              )}
          </div>
        )}
      </div>
    </>
  );
}

export default GameCard;

// function NoData() {
//   <div className="no-data">
//     <div className="material-icons icon">assignment</div>
//     <div className="text" text_key="CASINO__NO_GAMES">
//       No games
//     </div>
//   </div>;
// }
