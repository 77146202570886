/* eslint-disable react-hooks/exhaustive-deps */
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/grid";
import "swiper/css/pagination";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useState } from "react";
import useDynamicRefs from 'use-dynamic-refs';
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, FreeMode, Navigation } from "swiper/modules";
import { Favorite, FavoriteBorder, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

import play from "../../assets/images/icons/play.svg";
import { useMyContext } from "../context";
import { getLandingGames } from "../../utility/Apis";
import { useChangeUserFavorites } from "../../hooks/useChangeUserFavorites";
import { Tooltip } from "@mui/material";

function Lobby({ setShowLobbyGames }) {
  const {
    loading,
    setLoading,
    setIsGamePlaying,
    handleLoginShow,
    favorites,
    setActiveCategory,
  } = useMyContext();
  const [landingGames, setLandingGames] = useState([]);
  const [getRef, setRef] = useDynamicRefs();
  const { changeUserFavorites } = useChangeUserFavorites();

  const handleClickFavorite = async (gameId) => changeUserFavorites(gameId);

  // landing games api is calling here
  const fetchLandingGames = async () => {
    try {
      setLoading(true);
      sessionStorage.removeItem("pageSize");
      const res = await getLandingGames();
      if (res?.msg === "success") {
        setLandingGames(res?.data);
      } else {
        toast.error(res?.msg, { toastId: "game-error" });
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLandingGames();
    // eslint-disable-next-line
  }, []);

  const settings = {
    slidesPerView: 2,
    freeMode: true,
    // grabCursor: true,
    speed: 100,
    mousewheel: {
      releaseOnEdges: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
        grid: {
          rows: 2,
          fill: "row",
        },
      },
      425: {
        slidesPerView: 2,
        spaceBetween: 10,
        grid: {
          rows: 3,
          fill: "row",
        },
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 10,
        grid: {
          rows: 3,
          fill: "row",
        },
      },
      1100: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      1300: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
      1600: {
        slidesPerView: 6,
        spaceBetween: 18,
      },
    },
  };

  const navigate = useNavigate();

  const handleClickSeeMore = (category) => {
    const newActiveSubcategory = landingGames[category]?.games[0]
    ?.masterGameSubCategoryId
    setActiveCategory(newActiveSubcategory)
    window.scrollTo(0,0)
    setShowLobbyGames(false);
  };

  const handleClickPlay = (game) => {
    if (!localStorage.getItem("token")) {
      handleLoginShow();
    } else {
      navigate(`/game/${game?.masterCasinoGameId}`);
      // setGame(game);
      setIsGamePlaying(true);
      const scrollPos = window.pageYOffset;
      sessionStorage.setItem("scrollPosition", `${scrollPos}`);
    }
  };

  useEffect(() => {
    if (!loading) {
      const savedScrollPosition = sessionStorage.getItem("scrollPosition");
      if (savedScrollPosition) {
        window.scrollTo(0, parseInt(savedScrollPosition));
        // sessionStorage.removeItem("scrollPosition");
      }
    }
  }, [Object.keys(landingGames)?.length > 0, loading]);
  return (
    <>
      <section className="mini-games game-head-wrapper">
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 99,
              textAlign: "center",
              height: "80vh",
              width: "100%",
            }}
          >
            <Spinner
              as="span"
              animation="border"
              size="md"
              role="status"
              aria-hidden="true"
              variant="light"
              style={{ height: "50px", width: "50px" }}
            />
          </div>
        ) : (
          Object.keys(landingGames)?.map((category, index) => (
            <div className="mini-games-wrapper" key={`${index}-${category}`}>
              <div className="head-wrapper">
                <div className="lobby__header">
                  <p>{category}</p>
                  <div className="lobby__navigation">
                    <button
                      className="lobby__navigation-button"
                      ref={ref => { 
                        if (!getRef(`prev${category}`)?.current) {
                          setRef(`prev${category}`)
                        }
                        getRef(`prev${category}`).current = ref}
                      }
                    >
                      <KeyboardArrowLeft />
                    </button>
                    <button
                      className="lobby__navigation-button"
                      ref={ref => { 
                        if (!getRef(`next${category}`)?.current) {
                          setRef(`next${category}`)
                        }
                        getRef(`next${category}`).current = ref}
                      } 
                    >
                      <KeyboardArrowRight />
                    </button>
                    {category !== "The Holidays" && (
                      <button
                        className="lobby__see-more" 
                        onClick={() => handleClickSeeMore(category)}
                      >
                        See more
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="game-swiper-container">
                <Swiper
                   navigation={{
                    prevEl: getRef(`prev${category}`)?.current,
                    nextEl: getRef(`next${category}`)?.current,
                  }}
                  onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = getRef(`prev${category}`)?.current;
                    swiper.params.navigation.nextEl = getRef(`next${category}`)?.current;
                  }}
                  {...settings}
                  modules={[Grid, FreeMode, Navigation]}
                >
                  {landingGames[category]?.games?.map((game) => (
                    <SwiperSlide key={game?.masterCasinoGameId}>
                      <div className="game-swiper-slider">
                        <div className="game-swiper-slider-img">
                          <div
                            className="game-thumb-container"
                            key={game?.masterCasinoGameId}
                          >
                            <img src={game?.thumbnailUrl} alt={game?.name} />
                          </div>
                          <div
                            className="slot-hover"
                          >
                            <span>
                              <img
                                className="play"
                                src={play}
                                alt="play icon"
                                onClick={() => handleClickPlay(game)}
                              />
                            </span>
                            <div className="star-wrapper">
                              {favorites?.length > 0 &&
                                favorites?.includes(game?.masterCasinoGameId)
                                  ? (
                                    <Favorite
                                      onClick={() =>
                                        handleClickFavorite(game?.masterCasinoGameId)
                                      }
                                    />
                                  ): (
                                    <FavoriteBorder 
                                      onClick={() =>
                                        handleClickFavorite(game?.masterCasinoGameId)
                                      }
                                    />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="game__name-wrapper">
                        <Tooltip
                          followCursor
                          enterDelay={600}
                          title={game?.name}
                        >
                          <p>
                            {game?.name}
                          </p>
                        </Tooltip>
                        <p>
                          {game["MasterCasinoProvider.name"]}
                        </p>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          ))
        )}
      </section>
    </>
  );
}

export default Lobby;
