import React, { useState } from "react";
// import accept from "../../assets/images/header/auth/icon/accept5ed3.svg";
import { toast } from "react-toastify";
import { Spinner, Button } from "react-bootstrap";
import { IconButton } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { useForm } from "react-hook-form";
import { registerUser } from "../../../utility/Apis";
import { useMyContext } from "../../context";
// import SocialLogin from "./SocialLogin";

function SignupForm({ handleSignupClose, handleLoginShow }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { loading, setLoading } = useMyContext();
  const [showPassword, setShowPassword] = useState(false);
  // ======react query api call =======================
  const mutation = useMutation({
    mutationFn: async (userData) => await registerUser(userData),
    onMutate: () => {
      // Set loading to true when the mutation starts
      setLoading(true);
    },
    onSuccess: (data) => {
      // const {status } = data || {};
      if (data?.status === 200) {
        // console.log("hjbcdsdss---", token);
        toast.success("Register Succcessfully", {
          toastId: "register-success",
          autoClose: 2000,
        });
        setLoading(false);
        handleSignupClose();
        handleLoginShow();
      } else {
        setLoading(false);
        console.log("Something went wrong");
        toast.error(data?.message, {
          toastId: "register-error",
          autoClose: 2000,
        });
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    onSettled: () => {
      // Reset loading state when the mutation is completed (success or failure)
      setLoading(false);
    },
  });

  // Regular expression pattern for username
  // function isValidUsername(username) {
  //   // Regular expression pattern for username
  //   const usernameRegex = /^[a-zA-Z0-9_-]{3,16}$/;

  //   // Test the username against the regex pattern
  //   return usernameRegex.test(username);
  // }
  // Regular expression pattern for email
  // const emailRegex =
  //   /^[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/;

  // function isValidEmail(email) {
  //   return emailRegex.test(email);
  // }

  const formSubmit = (data, e) => {
    // console.log(data);
    e.preventDefault();
    mutation.mutate({ ...data, agentId: data.agentId.toUpperCase() });
  };

  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <ul className="auth-container__fields">
        <li className="auth-container__input-wrapper">
          <label htmlFor="username">Email or username</label>
          <input
            type="text"
            name="userName"
            placeholder="Enter your username"
            placeholder_key="SIGNUP__USERNAME"
            autoComplete="off"
            {...register("userName", {
              required: "Username is required.",
              pattern: {
                value: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9_]+$/,
                message: "Username must only contain letters, numbers",
              },
              minLength: {
                value: 5,
                message: "Username must have at least 5 characters.",
              },
              maxLength: {
                value: 20,
                message: "Username must not exceed 20 characters",
              }, // Adding maxLength validation
            })}
            className={`${errors?.userName && "dirty invalid"}`}
          />
          {errors?.userName && errors?.userName?.message && (
            <li
              className="err-text"
              field="UserName"
              style={{ textAlign: "start" }}
            >
              {errors?.userName?.message}
            </li>
          )}
        </li>
        <li className="auth-container__input-wrapper">
          <label htmlFor="password">Password</label>
          <input
            type={!showPassword ? "password" : "text"}
            name="password"
            placeholder="Enter your password"
            placeholder_key="SIGNUP__PASSWORD"
            autoComplete="off"
            maxLength={16}
            {...register("password", {
              required: "Password is required.",
              // pattern: {
              //   value:
              //     // eslint-disable-next-line
              //     /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':".,/<>?\|`~])(?=.*\d)(?=.{8,})/,
              //   message:
              //     "Password must contain at least one uppercase letter, one special character, one number, and be at least 8 characters long",
              // },
              pattern: {
                value:
                  // eslint-disable-next-line
                  /^.{5,16}$/,
                message: "Password must have at least 5 characters.",
              },
            })}
            className={`${
              errors?.password && "dirty invalid"
            } input-sub-icon`}
          />
           <IconButton 
            className={`show-password ${errors?.password && "--error"}`}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            {showPassword ? (<Visibility />) : (<VisibilityOff />)}
          </IconButton>
          {errors?.password && errors?.password?.message && (
            <li
              className="err-text"
              field="Password"
              style={{ textAlign: "start" }}
            >
              {errors?.password?.message}
            </li>
          )}
        </li>
        <li className="auth-container__input-wrapper">
          <label htmlFor="agentId">Agent ID</label>
          <input
            type="text"
            name="agentId"
            placeholder="Agent ID"
            placeholder_key="AGENT_ID"
            autoComplete="off"
            maxLength="5"
            {...register("agentId", {
              required:
                "Agent ID is required. (Contact your agent for ID)",
              minLength: {
                value: 5,
                message: "Agent ID must be at least 5 characters long",
              },
              maxLength: {
                value: 5,
                message: "Agent ID must be at most 5 characters long",
              },
              pattern: {
                value: /^[a-zA-Z0-9]{5}$/,
                message:
                  "Agent ID must be alphanumeric and exactly 5 characters long",
              },
            })}
            className={`${errors?.agentId && "dirty invalid"}`}
            onKeyDown={(e) => {
              // Prevent entering non-alphanumeric values (except for navigation keys like backspace)
              if (e.key.match(/[^a-zA-Z0-9]/) && e.key !== "Backspace") {
                e.preventDefault();
              }
            }}
            onInput={(e) => {
              e.target.value = e.target.value.toUpperCase();
            }}
          />
          {errors.agentId && errors.agentId.message && (
            <li
              className="err-text"
              field="Email"
              style={{ textAlign: "start" }}
            >
              {errors?.agentId?.message}
            </li>
          )}
        </li>
        {/* <FormControlLabel
          className="auth-container__fields-terms"
          control={<Checkbox required />} 
          label={<p>I agree to <b>Terms & Conditions</b> and the <b>Privacy Policy</b> of the Company</p>}
        /> */}
        <Button className="auth-container__fields-button" text_key="SIGNUP__REGISTER" type="submit">
          {loading ? (
            <div style={{ zIndex: 99, textAlign: "center" }}>
              <Spinner
                as="span"
                animation="border"
                size="md"
                role="status"
                aria-hidden="true"
                variant="light"
              />
            </div>
          ) : (
            "Sign up"
          )}
        </Button>
        {/* <SocialLogin /> */}
      </ul>
    </form>
  );
}

export default SignupForm;
